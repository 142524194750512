// INFO : This form will be rendered in the /create-ticket route and will use dynamic tickets feature to generate ticket using OpenAI.
// .... : Apart from /create-ticket, this ticket will also render as unprotected route /AI as public facing screen for creating request.

import { useAuth0 } from "@auth0/auth0-react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import fileUplodWithProxyServer from "../../ticket-details/api-call-functions/fileUploadWithProxyServer";
import ErrorText from "../../utils/ErrorText";
import AppLoader from "../../utils/Loader";
import UploadFileList from "../../utils/UploadFileList";
import {
  fileDeleteHandler,
  fileHandler,
  handleDrag,
  handleDropFile,
} from "../../utils/dragDropFileHandlers";
import emailValidator from "../../utils/emailValidator";
import getUserIPAddress from "../../utils/getUserIPAddress";
import {
  categoriesMap,
  defaultPlaceholderText,
  fileUploadText,
  getHeader,
  loaderTextArray,
  openAIEndpoint,
  parseSubjectString,
  prepareTicketFormData,
  requestBody_GenerateResponse,
  requestBody_GenerateSubject,
  requestText,
} from "../ticketFormUtil";

const TicketsFormWithAI = ({ toneFilter, personaFilter }) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const requestDetailsRef = useRef();
  const [emailError, setEmailError] = useState(false);
  const [requestDetails, setRequestDetails] = useState("");
  const [reqDetailsError, setReqDetailsError] = useState(false);
  const [loaderText, setLoadingText] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileNameArray, setFileNameArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [email, setEmail] = useState(user?.email ? user.email : "");
  const [placeholderText, setPlaceholderText] = useState(
    defaultPlaceholderText
  );
  const ticketCategory =
    categoriesMap[useSelector((state) => state.tickets.activeCategoryIndex)];

  // This handler will keep changing text of loader @ every 4.5 seconds
  const loaderTextHandler = () => {
    let textArray = [...loaderTextArray];
    setLoadingText(textArray.pop());

    setInterval(() => {
      if (textArray.length) {
        setLoadingText(textArray.pop());
      }
    }, 4500);
  };

  const FilterContent = (Content) => {
    if (toneFilter?.key !== "") {
      Content = Content + " \n" + toneFilter?.value;
    }

    if (personaFilter?.key !== "") {
      Content = Content + " " + personaFilter?.value;
    }

    //TODO for future use
    // if (personaFilter?.length > 0) {
    //   personaFilter?.map(
    //     (persona) => (Content = Content + " " + personaFilter?.value)
    //   );
    // }
    return Content;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    loaderTextHandler();

    let fileUploadCounter = 0;
    const fileURLArray = [];
    const fileUploadErrors = [];
    const filesCount = Object.keys(filesToUpload).length;

    const callbackFunction = (fileResponseData) => {
      if (fileResponseData.status !== "OK") {
        fileUploadErrors.push(
          `File Name : ${fileNameArray[fileUploadCounter].name} | Error : ${fileResponseData.error}`
        );
      } else {
        fileURLArray.push(fileResponseData.url);
      }
      // Updating File Upload Counter
      ++fileUploadCounter;
      if (fileUploadCounter === filesCount) {
        // console.log("All Files Uploaded | Send request to create ticket->");
        // Resetting the files array state ==>
        setFilesToUpload([]);
        setFileNameArray([]);
        submitTicketWithProxyServer(fileURLArray);
      }
    };

    //If attachments, we will first upload files, get their ids and then submit API request to create emails
    if (filesCount) {
      // console.log("Calling File Upload");
      Object.keys(filesToUpload).forEach((key) =>
        fileUplodWithProxyServer(filesToUpload[key], callbackFunction)
      );
    } else {
      // If no attachments, then directly submit API request to create emails.
      submitTicketWithProxyServer();
    }
  };

  const submitTicketWithProxyServer = async (fileURLArray = []) => {
    console.log(
      "first ==> ",
      firstNameRef?.current?.value ? "hihi" : "hahah",
      lastNameRef?.current?.value
    );
    try {
      // Request A : To generate Subject Line via ChatGPT
      const response_A = await fetch(openAIEndpoint, {
        method: "POST",
        headers: getHeader(),
        body: requestBody_GenerateSubject(requestDetails),
      });
      if (!response_A.ok) throw new Error("Something Went Wrong !");
      const responseBody_A = await response_A.json();
      let subjectLine = responseBody_A?.choices[0]?.message?.content;
      subjectLine = parseSubjectString(subjectLine);

      // Request B : To generate ticket content via ChatGPT for HubSpot Ticket
      const response_B = await fetch(openAIEndpoint, {
        method: "POST",
        headers: getHeader(),
        body: requestBody_GenerateResponse(
          FilterContent(ticketCategory?.value + " \n" + requestDetails)
        ),
      });

      if (!response_B.ok) throw new Error("Something Went Wrong !");
      const responseBody_B = await response_B.json();
      const parsedData = responseBody_B?.choices[0]?.message?.content;

      const userText = `\n\n— ORIGINAL REQUEST —\n\nTone: ${toneFilter?.key}\nPersona: ${personaFilter?.key}\n\n${requestDetails}`;

      // Request C : To create ticket on HubSpot with Subject Line and Req Body recieved from ChatGPT
      const rawFormData = [
        firstNameRef?.current?.value,
        lastNameRef?.current?.value,
        user?.email ? user.email : email,
        subjectLine,
        parsedData + userText,
      ];

      const cookies = document.cookie.split("; ");
      let hubspotutk = "";

      for (const cookie of cookies) {
        const [name, value] = cookie.split("=");
        if (name === "hubspotutk") {
          hubspotutk = value;
          break;
        }
      }

      const ip_address = await getUserIPAddress();
      const page_url = window.location.href;
      const page_name = document.title;

      const hs_context = {
        hutk: hubspotutk,
        ipAddress: ip_address,
        pageUri: page_url,
        pageName: page_name,
      };

      const response_C = await fetch(process.env.REACT_APP_CREATENEWTICKET, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: prepareTicketFormData(
          rawFormData,
          fileURLArray,
          hs_context,
          ticketCategory?.label
        ),
      });
      const responseBody_C = await response_C.json();
      if (responseBody_C.status !== "OK") throw new Error(responseBody_C.error);
      setRequestDetails("");
      setEmail("");
      setPlaceholderText("Ticket Submitted successfully");
      if (!isAuthenticated) {
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        setLoadingText(
          "Congratulations ! Your ticket is submitted successfully."
        );
      }

      setTimeout(() => {
        // Adding delay as on immediate navigate to homepage, HS DB is not returning ticket just created
        setIsLoading(false);
        setPlaceholderText(defaultPlaceholderText);
        isAuthenticated && window.location.replace("/");
      }, 3000);
    } catch (err) {
      console.log(err.message);
      setIsLoading(false);
      setPlaceholderText(
        "Something went wrong. We would suggest to try again. We deeply regret the inconvenience !"
      );
      setTimeout(() => {
        setIsLoading(false);
        setPlaceholderText(defaultPlaceholderText);
      }, [3000]);
    }
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
    if (emailValidator(e.target.value) === "invalid") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const requestDetailsHandler = (e) => {
    setRequestDetails(e.target.value);
    e.currentTarget.value.length < 2
      ? setReqDetailsError(true)
      : setReqDetailsError(false);
  };

  const Label = ({ htmlFor, text }) => {
    return (
      <label
        htmlFor={htmlFor}
        className="text-sm md:text-base  lg:text-lg font-bold  mb-2"
      >
        {text}
      </label>
    );
  };

  return (
    <form
      onSubmit={submitHandler}
      className="px-9 pt-2 sm:pt-9 pb-9  bg-beige1 sm:bg-ogWhite rounded-sm shadow-md"
    >
      <div className="flex items-center justify-start flex-wrap mb-12 w-full md:w-4/5">
        {!isAuthenticated ? (
          <>
            <FormControl className="w-full sm:w-1/2 !mb-5 sm:!pr-4 ">
              <Label htmlFor="first_name" text="First Name" />
              <OutlinedInput
                id="first_name"
                fullWidth={true}
                className="bg-ogWhite"
                inputRef={firstNameRef}
              ></OutlinedInput>
            </FormControl>
            <FormControl className="w-full sm:w-1/2 !mb-5 sm:!pr-4">
              <Label htmlFor="last_name" text="Last Name" />
              <OutlinedInput
                id="last_name"
                fullWidth={true}
                className="bg-ogWhite"
                inputRef={lastNameRef}
              ></OutlinedInput>
            </FormControl>

            <FormControl className="w-full sm:w-1/2 !mb-5 sm:!pr-4">
              <Label htmlFor="Email" text="Email" />
              <OutlinedInput
                id="email"
                fullWidth={true}
                value={email}
                className="bg-ogWhite"
                onChange={emailHandler}
                onBlur={emailHandler}
              ></OutlinedInput>
              <ErrorText text={"Invalid Email"} error={emailError} />
            </FormControl>
          </>
        ) : null}
        <FormControl className="w-full">
          <Label htmlFor="ticket_content" text="Describe Your Campaign" />
          <p className="mb-5 text-sm md:text-base text-mildGrey">
            {requestText}
          </p>
          <OutlinedInput
            id="ticket_content"
            fullWidth={true}
            multiline
            minRows={8}
            value={requestDetails}
            className="bg-ogWhite text-sm sm:!text-lg "
            placeholder={placeholderText}
            inputRef={requestDetailsRef}
            onChange={requestDetailsHandler}
            onBlur={requestDetailsHandler}
          ></OutlinedInput>
          <ErrorText
            text={"Please enter request details"}
            error={reqDetailsError}
          />
        </FormControl>
      </div>
      <div className="mb-5">
        <Label htmlFor="first_name" text="Submit Attachments" />
        <p className="mb-5 text-sm md:text-base text-mildGrey">
          {fileUploadText}
        </p>
        <UploadFileList
          onClick={fileDeleteHandler.bind(
            null,
            setFileNameArray,
            fileNameArray,
            setFilesToUpload,
            filesToUpload
          )}
          fileNameArray={fileNameArray}
        />
        <label
          htmlFor="attachments"
          className="border-2 border-mildGrey p-4 rounded-md font-normal hover:cursor-pointer text-sm md:text-base lg:text-lg"
          onDrop={(e) =>
            handleDropFile(
              e,
              setFilesToUpload,
              filesToUpload,
              setFileNameArray,
              fileNameArray
            )
          }
          onDragEnter={(e) => handleDrag(e)}
          onDragOver={(e) => handleDrag(e)}
        >
          Choose Files
        </label>
        <input
          id="attachments"
          type="file"
          multiple={true}
          onChange={(e) =>
            fileHandler(
              e,
              setFilesToUpload,
              filesToUpload,
              setFileNameArray,
              fileNameArray
            )
          }
          className="invisible"
        />
      </div>
      <button
        type="submit"
        disabled={
          email === "" ||
          requestDetails?.length < 2 ||
          emailError ||
          reqDetailsError
        }
        className="bg-mwOrange2 vs:mt-6 cursor-pointer rounded-md w-48 p-4 text-white text-sm md:text-base lg:text-lg font-semibold disabled:bg-slate-400 disabled:cursor-not-allowed"
      >
        Send Now
      </button>
      {isLoading ? (
        <AppLoader
          text={loaderText}
          text2="Please do not close your browser tab/window to ensure project ticket is created."
        />
      ) : null}
    </form>
  );
};

export default TicketsFormWithAI;
