import { Fragment, useEffect, useState } from "react";
import SendMessage from "../components/ticket-details/SendMessage";
import ViewAllTickets from "../components/utils/ViewAllTickets";
import { useSearchParams } from "react-router-dom";
import { stagingStatusMap, prodStatusMap } from "./statusMap";
import TicketSummary from "../components/ticket-details/TicketSummary";
import ThreadMessages from "../components/ticket-details/ThreadMessages";
import { useDispatch } from "react-redux";
import { threadsActions } from "../store/redux-index";
import LoaderText from "../components/utils/LoaderText";
import AttachmentsList from "../components/ticket-details/AttachmentsList";

const TicketsDetailPage = () => {
  const [searchParams] = useSearchParams();
  const [ticketDetails, setTicketsDetails] = useState({
    id: "",
    status: "",
    subject: "",
    error : false
  });
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
// "Loading Ticket Details | Please wait ..."

  useEffect(()=>{
    dispatch(threadsActions.resetAttachmentsList())
  },[])

  useEffect(() => {
    const ticketStatusMap =
      process.env.REACT_APP_STATUSMAP === "staging"
        ? stagingStatusMap
        : prodStatusMap;

    const fetchTicketDetailsWithProxyServer = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(process.env.REACT_APP_GETTICKETDETAILS, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ticketId: searchParams.get("ticketId") }),
        });

        const responseData = await response.json();
        const jsonData = JSON.parse(responseData);

        setTicketsDetails({
          id: searchParams.get("ticketId"),
          status: ticketStatusMap[jsonData.properties.hs_pipeline_stage],
          subject: jsonData.properties.subject,
        });
        setIsLoading(false);
        // console.log('Response --> ',jsonData)
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
        setTicketsDetails({
          id: searchParams.get("ticketId"),
          status : 'Unknown',
          subject: 'Error Occured While Fetching Ticket Summary',
          error : error.message
        });
      }
    };
    dispatch(threadsActions.setFetchAllMessages(true))
    fetchTicketDetailsWithProxyServer();
  }, [searchParams,dispatch]);

  return (
    <Fragment>
      <ViewAllTickets />

      {isLoading && <LoaderText shouldDisplay={true} text="Loading Ticket Details | Please wait ..."/>}
      {!isLoading && <div className="w-11/12 md:w-1/2 mx-auto mt-28">
          <TicketSummary
            ticketId={ticketDetails.id}
            subject={ticketDetails.subject}
            status={ticketDetails.status}
            error = {ticketDetails.error}
          />
          <ThreadMessages threadIdsString={searchParams.get("threadId")} />
          <SendMessage ticketSubject={ticketDetails.subject}/>
          <AttachmentsList/>
        </div>}
    </Fragment>
  );
};

export default TicketsDetailPage;
