// INFO : This component will render in Ticket Details Page beneath 'Send Message' box.
// ...    This component will render all attachments in a thread as a list.

import AttachmentCard from "./AttachmentCard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const AttachmentsList = () => {
  const filesList = useSelector((state) => state.threads.attachmentsList);
  const [flatFileList, setFlatFileList] = useState([])
//   const flatFilesList = 

  useEffect(() => {
    // console.log("Files List", filesList.flatMap(x => x));
    setFlatFileList(filesList.flatMap(x => x))
  }, [filesList.filter((x) => !!x).length]);

  return (
    <section className="bg-ogWhite border-2 border-borderGrey mb-5 py-2 px-4">
      <p className="font-medium mb-5">Attachments</p>
      {flatFileList.map((file,index) => (
        <AttachmentCard
          data={file?.data}
          status={file?.status}
          url={file?.url}
          key={index}
        />
      ))}
      {flatFileList.length===0 ? <p>Any attachments will display here</p> : null}
    </section>
  );
};

export default AttachmentsList;
