// This function will be used by 'MessageCard.js' for extracting sender name to display in the message card. 

const getSenderName = (messageText,senderActor) => {
    let senderName;
    if (messageText.search("MW Support bot") > -1) {
      // do some thing
      const externalSenderInfoString = messageText
        .slice(
          messageText.search("This email"),
          messageText.search("MW Support bot")
        )
        .split(" ");
      // console.log('String',externalSenderInfoString)

      senderName = externalSenderInfoString.includes("from")
        ? externalSenderInfoString[externalSenderInfoString.indexOf("from") + 1]
        : "N/A";
    } else {
      senderName = senderActor[0].name.split(" ")[0];
    }

    return senderName;
  };

  export default getSenderName