// INFO : This component will add a backdrop and show circular progress when mounted

import { Box } from "@mui/material";

function AppLoader({ text = "", text2 = "" }) {
  return (
    <Box
      style={{
        position: "fixed",
        zIndex: 1400,
        top: 0,
        left: 0,
        minHeight: "100vh",
        width: "100vw",
        backgroundColor: "rgb(17 17 17 / 65%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box className="flex flex-col items-center w-11/12 vs:w-4/5">
        <img src="./loading-gif.webp" className="w-12" alt="Loading GIF"/>
        {text ? (
          <Box className="text-center text-lg font-bold text-ogWhite">{text}</Box>
        ) : null}
        {text2 ? (
          <Box className="text-center text-lg font-bold  text-ogWhite mt-2">{text2}</Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default AppLoader;
