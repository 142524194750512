export const stagingStatusMap = {
  1: "New",
  2: "Waiting on Client",
  3: "Developing Creative Brief",
  46672442: "Art Direction Needed",
  46672443: "Revisions Needed",
  46672444: "Assigned to Copy Team",
  46672445: "Assigned to Design Team",
  46672446: "Assigned to Data Team",
  46672447: "In Development",
  46672448: "Needs Internal Review",
  46672449: "Under Client Review",
  46672450: "Ready for Publishing",
  12: "Closed",
  13: "On Hold",
  10:"Ads Running"
};
export const prodStatusMap = {
  1: "New",
  14952344: "Waiting on Client",
  2: "Developing Creative Brief",
  15618430: "Art Direction Needed",
  11997003: "Revisions Needed",
  3: "Assigned to Copy Team",
  14952345: "Assigned to Design Team",
  10055854: "Assigned to Data Team",
  14952346: "In Development",
  14952347: "Needs Internal Review",
  14952348: "Under Client Review",
  14952350: "Ready for Publishing",
  4: "Closed",
  14952349: "On Hold",
  55119646:"Ads Running"
};
