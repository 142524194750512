// INFO : This component will create a navigation list which can be used in Header and footer
import SignOutButton from "./header/SignOutButton";
import { Link } from "react-router-dom";

const getNavList = (brandURL, fileUploadURL) => {
  const navList = [
    <Link to="/create-ticket">aiAssistant</Link>,
    <Link to="/other-ticket">Submit Ticket</Link>,
    <a
      href={
        !!brandURL
          ? brandURL
          : "https://share.hsforms.com/1at3rRTL5Sn2iA8qeLkYteg1fobm"
      }
      target="_blank"
      rel="noreferrer"
    >
      Digital Assets
    </a>,
    <Link to="/reports">Reports</Link>,
    fileUploadURL ? (
      <a href={fileUploadURL} rel="noreferrer" target="_blank">
        Upload Files
      </a>
    ) : null,
    <SignOutButton />,
  ];
  return navList;
};

export default getNavList;
