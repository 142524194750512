// INFO : This component will render all the prompt options in form of Tab-based-Carousel

import { IconButton } from "@mui/material";
import { useRef } from "react";
import { categoriesMap } from "../ticketFormUtil";
import { useDispatch, useSelector } from "react-redux";
import { ticketsActions } from "../../../store/redux-index";

let activeImgColor =
  "invert(68%) sepia(92%) saturate(1159%) hue-rotate(346deg) brightness(99%) contrast(96%)";
let lockedImgColor =
  "invert(74%) sepia(9%) saturate(357%) hue-rotate(179deg) brightness(87%) contrast(86%)";

const CategoriesTab = () => {
  const myRef = useRef();
  const activeCategoryIndex = useSelector(
    (state) => state.tickets.activeCategoryIndex
  );
  const dispatch = useDispatch();

  const clickHandler = (offset) => {
    myRef.current.scrollLeft += offset;
  };

  const CategoryItem = ({ category, index }) => {
    let isActive = index === activeCategoryIndex;

    const categorySelector = () => {
      dispatch(ticketsActions.setActiveCategoryIndex(index));
    };
    return (
      <div
        onClick={category.locked ? null : categorySelector}
        className={`flex items-center gap-4 mr-2 sm:mr-8 ml-2 sm:ml-8 min-w-fit h-full  border-b-4 ${
          isActive ? " border-mwOrange2" : "border-transparent"
        } ${
          category.locked
            ? "text-mildGray5 cursor-not-allowed"
            : "cursor-pointer"
        } `}
      >
        <img
          src={category.imgSrc}
          alt={category.label}
          style={{
            filter: isActive
              ? activeImgColor
              : category.locked
              ? lockedImgColor
              : "",
          }}
          className="w-8 sm:w-11"
        />
        <span className="w-fit text-xs sm:text-base font-bold flex items-center gap-2">
          {category.label}
          {category.locked ? <img src="./icons/lockIcon.svg" alt="lock" /> : null}
        </span>
      </div>
    );
  };

  return (
    <div className="h-[80px] border-y  border-borderGrey mb-9  relative">
      <div
        className="flex flex-row w-4/5 md:w-11/12 mx-auto overflow-auto sm:overflow-hidden items-center  h-full"
        ref={myRef}
      >
        {categoriesMap.map((x, index) => (
          <CategoryItem category={x} index={index} key={index} />
        ))}
      </div>
      <div className="absolute left-0 top-0 h-[80px] flex items-center">
        <IconButton onClick={clickHandler.bind(null, -100)}>
          <img src="./icons/arrowLeftCircleIcon.svg" />
        </IconButton>
      </div>
      <div className="absolute right-0 top-0 h-[80px] flex items-center">
        <IconButton onClick={clickHandler.bind(null, 100)}>
          <img src="./icons/arrowRightCircleIcon.svg" />
        </IconButton>
      </div>
    </div>
  );
};

export default CategoriesTab;
