// INFO : This is wrapper component to render blog card
import { memo } from "react";
const BlogCard = ({ blogLink, title, description, imgSrc }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row ">
      <div className="w-full md:w-3/5">
        <a href={blogLink} target="_blank" rel="noreferrer" className="font-semibold  hover:text-mwOrange">{title}</a>
        <p className="text-sm">{description.slice(0,250)} ...</p>
        <a className="text-sm font-semibold  hover:text-mwOrange" href={blogLink} target="_blank" rel="noreferrer">Read More</a>
      </div>
      <a href={blogLink} target="_blank" rel="noreferrer" className="mb-8 md:mb-0 md:pl-4 w-full md:w-[50%] "  >
        <img src={imgSrc} alt={title} className="rounded-xl w-full" />
      </a>
    </div>
  );
};


export default memo(BlogCard);