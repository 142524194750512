// INFO : Component to be rendered for each attachment file.

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Tooltip } from "@mui/material";
import fileSizeParser from "../utils/fileSizeParser";

const fallbackNameTxt = "Metadata Not Found But File is available for download";

const AttachmentCard = ({ data, url, status }) => {
  return (
    <a href={url} download="true" target="_blank" rel="noreferrer ">
      <Tooltip
        title={status === "OK" ? data.name : fallbackNameTxt}
        arrow
        placement="top"
      >
        <div className="bg-default rounded-sm m-2 p-2 font-medium text-xs flex flex-row items-center border border-borderGrey">
          <div className="w-1/5 mr-2">
            <InsertDriveFileIcon color="disabled" />
          </div>

          <div className="ml-2 w-4/5">
            <div className="truncate">
              {status === "OK" ? data.name : fallbackNameTxt}
            </div>
            {status === "OK" && <div>{fileSizeParser(data.size)}</div>}
          </div>
        </div>
      </Tooltip>
    </a>
  );
};

export default AttachmentCard;
