import MetricCard from "./metric-card";
import EmptyMetricCard from "./empyt-metric-card";

const AppEngagement = () => {
  const empty = true;
  return (
    <div>
      <>
        {empty ? (
          <EmptyMetricCard />
        ) : (
          <div className="flex flex-row overflow-x-auto p-1">
            <MetricCard
              iconUrl="./icons/downloadIcon.svg"
              metricValue={0}
              timePeriod={"Within the last 30 days"}
              title={"App Downloads"}
            />
            <MetricCard
              iconUrl="./icons/sessionIcon.svg"
              metricValue={0}
              timePeriod={"Within the last 30 days"}
              title={"Sessions"}
            />
            <MetricCard
              iconUrl="./icons/userIcon.svg"
              metricValue={0}
              timePeriod={"Within the last 30 days"}
              title={"Sessions Per User"}
            />
            <MetricCard
              iconUrl="./icons/leadsIcon.svg"
              metricValue={0}
              timePeriod={"Within the last 30 days"}
              title={"New Leads"}
            />
            <MetricCard
              iconUrl="./icons/notificationIcon.svg"
              metricValue={0}
              timePeriod={"Within the last 30 days"}
              title={"Delivered Push Notifications"}
            />
          </div>
        )}
      </>

      {!empty && (
        <p className="text-mildGrey2 text-sm font-semibold mt-10">
          These cards require an MW Connect platform package with MarketingWiz.
          The engagement possibilities are limitless by extending your digital
          footprint for customers.
        </p>
      )}
    </div>
  );
};

export default AppEngagement;
