// INFO : This file will access token from Dropbox API

const fetchAccessToken = async (callback) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic " + process.env.REACT_APP_DROPBOX_APPKEY_SECRET
  );
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append("refresh_token", process.env.REACT_APP_DROPBOX_REFRESH_TKN);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  try {
    const response = await fetch(
      "https://api.dropbox.com/oauth2/token",
      requestOptions
    );

    const responsBody = await response.json();
    // console.log('Response body ',responsBody,responsBody.access_token)
    callback({
      access_token: responsBody.access_token,
      expires_in: responsBody.expires_in,
      error: null,
    });

    return;
  } catch (err) {
    // console.log("Error while fetching token : ", err.message);
    callback({ error: err.message });
  }
};

export default fetchAccessToken;
