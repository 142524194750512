// This function will make API request to upload attachments to proxy server and will return file id via callback function.

const fileUplodWithProxyServer = async (file,cbFunc) => {
    const formdata = new FormData();
    formdata.append("file_upload", file);

    try {
      const response = await fetch(process.env.REACT_APP_UPLOADFILE, {
        method: "POST",
        body: formdata,
        redirect: "follow",
      });

      const responseData = await response.json();
      // console.log("File Upload Response -> ", responseData);

      // if(responseData.status!=='OK') throw new Error(responseData.error)

      cbFunc(responseData)
    } catch (error) {
      // console.log("Error While File Upload -> ", error.message);
      cbFunc({status : '400',error : error.message})
    }
  };

  export default fileUplodWithProxyServer