import { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import sanitize from "sanitize-html";
import AttachmentCard from "./AttachmentCard";
import getSenderName from "../utils/getSenderName";
import { useDispatch } from "react-redux";
import { threadsActions } from "../../store/redux-index";

const MessageCard = ({
  messageBody,
  lastActivity,
  senderActor,
  bottomBorder,
  attachments,
  messageIndex
}) => {
  const [showMoreText, setShowMoreText] = useState(true);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const avatar = senderActor[0].avatar;
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchAttachmentsDetails = async () => {
      const attachmentIdArray = attachments.map((x) => x.id);
      try {
        const response = await fetch(process.env.REACT_APP_ATTACHMENTDETAILS, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ attachmentIdArray }),
        });
        const responseData = await response.json();

        // Extracting required details from data ==>
        const parsedData = responseData.map((item) => {
          const temp = {
            status: item.status,
            id: item.attachmentId,
            data: item.data,
            url: attachments.find((x) => x.id === item.attachmentId).url,
          };
          return temp;
        });
        // console.log("File data ==> ", parsedData);
        setAttachmentsData(parsedData);
        // Updating attachments in Redux => 
        dispatch(threadsActions.updateAttachmentsList({messageIndex:messageIndex,data: parsedData}))

      } catch (error) {
        console.log("Error While Fetching Attachment Details: ", error.message);
      }
    };
    Boolean(attachments.length)
      ? fetchAttachmentsDetails()
      : setAttachmentsData([]);
  }, [attachments]);

  return (
    <section className={`${bottomBorder && "border-b"} border-borderGrey`}>
      <div className="p-2">
        <div className="flex flex-row items-center justify-between text-xs font-medium">
          <div className="flex flex-row  items-center">
            {senderActor[0].id !==
              process.env.REACT_APP_FALLBACK_ENDERACTORID && avatar ? (
              <img
                src={avatar}
                className="rounded-full"
                width="30px"
                height="30px"
                alt="sender avatar"
              />
            ) : (
              <div className="w-[30px] h-[30px] mr-1">
                <AccountCircleIcon color="disabled" fontSize="large" />
              </div>
            )}
            <div className="m-2">{getSenderName(messageBody, senderActor)}</div>
          </div>
          <div>{lastActivity}</div>
        </div>
        <p
          className={`my-5 mx-8 text-sm font-normal overflow-hidden ${
            showMoreText ? "h-20" : "h-fit"
          }`}
          dangerouslySetInnerHTML={{
            __html: sanitize(messageBody, {
              allowedTags: sanitize.defaults.allowedTags.concat([
                "img",
                "b",
                "strong",
              ]),
            }),
          }}
        ></p>
        {/* <StringToHTML/> */}
        <button
          onClick={() => {
            setShowMoreText((prev) => !prev);
          }}
          className="w-1/4 mx-375perc text-center font-medium  hover:text-orange-500 text-sm"
        >
          {showMoreText ? "Show More" : "Show Less"}
        </button>
      </div>
      <div className="flex flex-row flex-wrap">
        {attachmentsData.map((x) => (
          <div key={x.id} className="max-w-40perc">
            <AttachmentCard data={x.data} url={x.url} status={x.status} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default MessageCard;
