import { useEffect, useState } from "react";
import { lastActivity } from "../utils/dateParser";
import MessageCard from "./MessageCard";
import { useDispatch, useSelector } from "react-redux";
import { threadsActions } from "../../store/redux-index";
import { useAuth0 } from "@auth0/auth0-react";
import parseEmailText from "../utils/parseEmailText";
import fetchOwnerId from "./api-call-functions/fetchOwnerId";
import fetchContactDetails from "./api-call-functions/fetchContactDetails";
import LoaderText from "../utils/LoaderText";
import ErrorText from "../utils/ErrorText";

const ThreadMessages = ({ threadIdsString }) => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [msgLoadingError, setMsgLoadingError] = useState(null);
  const [allActorsData, setAllActorsData] = useState([]);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const fetchAllMessagesBeacon = useSelector(
    (state) => state.threads.fetchAllMessages
  );
  const [showOldMessages, setShowOldMessages] = useState(false);

  useEffect(() => {
    const threadIdArray = threadIdsString.split(" ");
    const fetchThreadMessages = async () => {
      setLoadingMessages(true);
      setMsgLoadingError(null);
      try {
        const response = await fetch(process.env.REACT_APP_GETTHREADMESSAGES, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ threadIdArray }),
        });
        const responseData = await response.json();
        const validResponseData = responseData.filter(
          (item) => item.status === "OK"
        );
        if (!validResponseData.length)
          throw new Error(
            `Error while fetching conversations : \n${responseData
              .map(
                (item) =>
                  `Thread Id : ${item.threadId} | Error : ${item.threadData}`
              )
              .join("\n")}`
          );

        // Filtering the right messages and then combining messages from all the threads ==>
        let filteredMessages = [];
        validResponseData.forEach((item) =>
          item.threadData.results
            .filter((item) =>
              ["INTEGRATION", "HUBSPOT"].includes(item?.client?.clientType)
            )
            .forEach((x) =>
              filteredMessages.push({ threadId: item.threadId, message: x })
            )
        );
        // console.log("Filtered Messages => ", filteredMessages);
        // Parsing required details from above array of messages ==>
        const parsedMessages = filteredMessages
          .map((item,index) => {
            const activityDate = item.message.updatedAt
              ? item.message.updatedAt
              : item.message.createdAt;
            const temp = {
              threadId: item.threadId,
              messageIndex : index,
              lastActivityNumeric: activityDate,
              lastActivity: lastActivity(activityDate),
              messageBody: item.message.richText
                ? item.message.richText
                : parseEmailText(item.message.text),
              senders: item.message.senders.map((x) => x.actorId),
              recipients: item.message.recipients.map((x) => x.actorId),
              channelId: item.message.channelId,
              channelAccountId: item.message.channelAccountId,
              messageType: item.message.type,
              attachments: item.message.attachments.map((x) => {
                return { id: x.fileId, url: x.url };
              }),
            };
            return temp;
          })
          .sort((x, y) =>
            y.lastActivityNumeric > x.lastActivityNumeric ? 1 : -1
          );

        // console.log("Message data => ", parsedMessages);
        const combinedActorIds = [
          ...new Set(
            parsedMessages
              .map((x) => x.senders.concat(x.recipients))
              .join()
              .split(",")
          ),
        ].filter( x => x!=='');
        // Setting Latest Thread Id to which messages can be posted ==>
        // For this , we need to remove 'COMMENTS' type messages as they dont have this data :
        const onlyMessages = parsedMessages.filter((x) => x.type !== "COMMENT");
        if (onlyMessages.length) {
          dispatch(threadsActions.setLatestThreadId(onlyMessages[0].threadId));
        }

        // console.log('Combined actors : ',combinedActorIds)
        // STEP # 2 : Sending API request to fetch actor details
        const actorResponse = await fetch(
          process.env.REACT_APP_GETACTORDETAILS,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ inputs: combinedActorIds }),
          }
        );

        const actorResponseData = await actorResponse.json();
        if (actorResponseData.status !== "OK")
          throw new Error(actorResponseData.error);
          
        // Step # 3 : Calling Owners API to get userId which will use as Agent Type Sender Actor Id matching with logged in user.
        // NOTE : If no results found, then we will fallback to default sender actor stored in env var

        fetchOwnerId(user.email, (ownerId) => {
          // console.log("Owner Id ", ownerId);
          dispatch(threadsActions.setSenderActorId(ownerId));
        });

        // Step # 4 : Calling Contact API to search for sender's firstname and lastname using logged in user email.
        fetchContactDetails(user.email, (name) => {
          // console.log("Contact Data :", name);
          dispatch(threadsActions.setExternalSenderName(name));
        });

        // removing recipient Actors data where emails are duplicating =>
        const uniqueEmails = [
          ...new Set(actorResponseData.results.map((x) => x.email)),
        ].filter((email) => email !== undefined);
        const uniqueRecipienActors = uniqueEmails.map((x) =>
          actorResponseData.results.find((y) => y.email === x)
        );

        // console.log('Unique Actors : ',uniqueRecipienActors)
        // console.log('All Actors : ',actorResponseData.results)
        // console.log('Unique Emails : ',uniqueEmails)
        setAllActorsData(actorResponseData.results);
        dispatch(threadsActions.setRecipientActorsData(uniqueRecipienActors));
        dispatch(threadsActions.setFetchAllMessages(false));
        setMessages(
          parsedMessages.sort((x, y) =>
            x.lastActivityNumeric > y.lastActivityNumeric ? 1 : -1
          )
        );
        setLoadingMessages(false);
        setMsgLoadingError(null);
      } catch (error) {
        console.log(error.message);
        setLoadingMessages(false);
        setMsgLoadingError(error.message);
      }
    };

    fetchAllMessagesBeacon && fetchThreadMessages();
  }, [threadIdsString, dispatch, user.email, fetchAllMessagesBeacon]);

  return (
    <div className="bg-ogWhite px-4 border-2 border-borderGrey rounded">
      {Boolean(messages.length > 3) && (
        <div className="flex flex-row justify-center">
          <button
            className=" bg-darkGrey rounded-2xl text-ogWhite px-2 py-1 text-xs font-normal hover:bg-slate-400"
            onClick={() => setShowOldMessages((prev) => !prev)}
          >
            {showOldMessages
              ? "↓  Hide Older Messages"
              : "↑  Show Older Messages"}
          </button>
        </div>
      )}
      {showOldMessages &&
        messages.slice(0, -3).map((msg, index) => (
          <div key={index}>
            <MessageCard
              messageBody={msg.messageBody}
              lastActivity={msg.lastActivity}
              senderActor={allActorsData.filter((x) => x.id === msg.senders[0])}
              bottomBorder={true}
              attachments={msg.attachments}
              messageIndex = {msg.messageIndex}
            />
          </div>
        ))}
      {messages.slice(-3).map((msg, index) => (
        <div key={index}>
          <MessageCard
            messageBody={msg.messageBody}
            lastActivity={msg.lastActivity}
            senderActor={allActorsData.filter((x) => x.id === msg.senders[0])}
            bottomBorder={
              messages.slice(-3).length === index + 1 ? false : true
            }
            attachments={msg.attachments}
            messageIndex = {msg.messageIndex}
          />
        </div>
      ))}
      {loadingMessages && (
        <LoaderText
          shouldDisplay={true}
          text={
            Boolean(messages.length)
              ? "Loading New Conversations"
              : "Loading Your Conversations"
          }
        />
      )}
      {Boolean(msgLoadingError) && (
        <ErrorText
          error={true}
          text={msgLoadingError}
          styles={{ textAlign: "center", padding: "2rem" }}
        />
      )}
    </div>
  );
};

export default ThreadMessages;
