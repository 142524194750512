// INFO : // Utility function to filter data and return filtered data

const getFilteredData = (
  allData,
  statusFilter,
  viewFilter,
  searchFilter,
  userCompanyId,
  userId
) => {

  const filterdByStatus =
    statusFilter === "All"
      ? allData
      : statusFilter === "All Open"
      ? allData.filter((item) => !['Closed',undefined].includes(item.status))
      : allData.filter((item) => item.status === statusFilter);

  const filteredByView =
    viewFilter === "My Tickets"
      ? filterdByStatus.filter((item) => item.contactIdList?.find(x => x ===userId))
      : filterdByStatus.filter((item) => item.companyId === userCompanyId);
  const filteredBySearch = filteredByView.filter(
    (item) =>
      item.id.includes(searchFilter) ||
      item.subject?.toLowerCase().includes(searchFilter?.toLowerCase())
  );

  const descSortedByActivity = filteredBySearch.sort((x, y) =>
    y.lastActiveNumeric > x.lastActiveNumeric ? 1 : -1
  );

  return descSortedByActivity;
};

export default getFilteredData;
