// INFO : This file contains all the handlers to create functionality of file drag-n-drop 




// Utility fn to collect file names form files object ==> 
export const getFileNamesArray = (filesArray,setFileNameArray,fileNameArray) => {
    const temp = Object.keys(filesArray).map((key) => {
      return {
        name: filesArray[key].name,
        url: URL.createObjectURL(filesArray[key]),
      };
    });
    const namesArray = temp.filter(
      (file) => !fileNameArray.map((x) => x.name).includes(file.name)
    );

    setFileNameArray([...fileNameArray, ...namesArray]);
  };

// Main handler that receives the files selected by user ==>
export const fileHandler = (event,setFilesToUpload,filesToUpload,setFileNameArray,fileNameArray) => {
  // console.log("Files ---> ", event.target.files);
  getFileNamesArray(event.target.files,setFileNameArray,fileNameArray);
  setFilesToUpload([...filesToUpload, ...event.target.files]);
};

  // Handler to handle file attachment when user tries to drag-drop =>
  export const handleDropFile = (event,setFilesToUpload,filesToUpload,setFileNameArray,fileNameArray) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFilesToUpload([...filesToUpload, ...event.dataTransfer.files]);
      getFileNamesArray(event.dataTransfer.files,setFileNameArray,fileNameArray);
    }
  };

  // Handler to delete files when user clicks the delete button ==> 
  export const fileDeleteHandler = (setFileNameArray,fileNameArray,setFilesToUpload,filesToUpload,name) => {
    // console.log(name);
    setFileNameArray(fileNameArray.filter((file) => file.name !== name));
    setFilesToUpload(
      Object.keys(filesToUpload)
        .map((key) => filesToUpload[key].name !== name && filesToUpload[key])
        .filter((i) => typeof i !== "boolean")
    );
  };

  //  Handler which would prevent default behaviour for drag-over and drag-enter events
  //  ...when user tries to attach file with drag-drop =>
  export const handleDrag = (event)=> {
    event.preventDefault();
    event.stopPropagation();
  };

