// INFO : This component will render blogs on homepage

import { Fragment, memo, useEffect, useState } from "react";
import BlogCard from "./blog-card";
import fetchBlogs from "./fetchBlogs";

const Blogs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchBlogs(setData);
  }, []);

  return (
    <Fragment>
      {!!data.length? <>
        <div className="flex flex-row justify-between items-baseline">
          <h1 className="text-2xl md:text-3xl mb-8">Latest from the Blog</h1>
          <a
            href="https://info.marketingwiz.co/blogs"
            target="_blank"
            className="underline "
            rel="noreferrer"
          >
            View All Blogs
          </a>
        </div>
        <div className="flex flex-col md:flex-row">
          {data.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className={`w-full md:w-1/2 p-4 bg-ogWhite rounded-xl ${
                index === 0 ? "mb-4 md:mb-0 md:mr-4" : 'mt-4 md:mt-0 md:ml-4'
              }`}
            >
              <BlogCard
                blogLink={item.blogLink}
                description={item.description}
                imgSrc={item.imgSrc}
                title={item.title}
              />
            </div>
          ))}
        </div>
      </> :
      <></>}
    </Fragment>
  );
};

export default memo(Blogs);
