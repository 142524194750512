import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";
import { Link } from "react-router-dom";
import HubSpotNavigation from "./HubspotNavigation";
import { useSelector } from "react-redux";
import getNavList from "../getNavList";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const hubspotURL = useSelector(
    (state) => state.reports.companyDetails.has_hubspot_url
  );
  const brandURL = useSelector(
    (state) => state.reports.companyDetails.has_brand_url
  );

  const fileUploadURL = useSelector(
    (state) => state.reports.companyDetails.portal_upload_file_nav
  );

  const clickHandler = () => {
    setShowMenu((prev) => !prev);
  };

  const drawerTop = Boolean(hubspotURL) ? "8.5rem" : "5.5rem";

  return (
    <header
      className=" bg-ogWhite sticky top-0 w-full"
      style={{
        boxShadow: "-1px 2px 13px 0 rgb(0 0 0 / 17%)",
        zIndex: "1400", // 1400 used as 1300 used by MUI Drawer component
      }}
    >
      {Boolean(hubspotURL) && <HubSpotNavigation hsURL={hubspotURL} />}
      <nav className="px-5 pb-2.5">
        <div
          className="flex flex-row mx-auto justify-between items-center"
          // MaxWidth used as defined in HubSpot Client Portal
          // style={{ maxWidth: "1240px" }}
        >
          <Link to="/">
            <img src="/small-logo.png" alt="marketingwiz logo" />
          </Link>
          {/* <EmailSequence /> */}

          <NavigationDesktop navList={getNavList(brandURL, fileUploadURL)} />
          <div className="md:hidden">
            <IconButton onClick={clickHandler}>
              {!showMenu ? (
                <MenuIcon sx={{ color: "#000000" }} fontSize="large" />
              ) : (
                <CloseIcon sx={{ color: "#000000" }} fontSize="large" />
              )}
            </IconButton>
          </div>
        </div>
        <Drawer
          // variant="permanent"
          anchor="top"
          open={showMenu}
          onClose={clickHandler}
          sx={{ [`& .MuiDrawer-paper`]: { top: drawerTop } }}
        >
          <NavigationMobile
            onClick={clickHandler}
            navList={getNavList(brandURL, fileUploadURL)}
          />
        </Drawer>
      </nav>
    </header>
  );
};

export default Header;
