// INFO : This function will filter reports data as per requirements and will return parsed data

import fetchFileTags from "./fetchFileTags";

const reportsParser = async (data, companyName, accessToken) => {
  return new Promise(async (resolve, reject) => {
    let cleanData;

    // ("strategic_wealth_partners");
    // Remove folder tag
    cleanData = data.filter((x) => x[".tag"] !== "folder");

    // cleanData = cleanData.map((dt) => dt["name"].toLowerCase());
    const newObjectArr = cleanData.map((obj) => {
      return { ...obj, name: obj.name.toLowerCase() };
    });
    if (companyName !== "marketing_wiz") {
      let firstName = companyName.split("_");
      cleanData = newObjectArr.filter((x) =>
        x.name.includes(firstName[0].toLowerCase())
      );
    } else {
      cleanData = newObjectArr.filter((x) => x.name.includes("marketing_wiz"));
    }
    cleanData = await fetchFileTags(cleanData, accessToken);
    // cleanData = fileNamesTagsMap.filter((x) => x.tags.includes(companyName));
    resolve(cleanData);
  });
};

export default reportsParser;
// telemus_capital_llc
