// INFO : This is utility component and renders a text with 'progress circle'
import { CircularProgress } from "@mui/material";
import parseEmailText from "./parseEmailText";
import sanitize from "sanitize-html";

const LoaderText = ({text, shouldDisplay,styles={}, showCircle=true}) => {
  return (
    <div className={`text-center font-bold px-4 m-4  py-2 mx-auto flex flex-row items-center justify-center font-roboto ${shouldDisplay ? 'visible' : 'invisible'}`} style={styles}>
      {!!text && <div className="mr-4" dangerouslySetInnerHTML={{__html:sanitize(parseEmailText(text))}}></div>}
      {showCircle && <CircularProgress color="inherit" size="2rem" />}
    </div>
  );
};

export default LoaderText
