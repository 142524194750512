// INFO : This component will render individual tickets thread

import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ErrorText from "../utils/ErrorText";

const TicketSummary = ({ ticketId, subject, status,error }) => {
  return (
    <section className="mb-5">
      <div className="flex flex-row items-center mb-5 ">
        <h1 className="text-2xl md:text-3xl font-normal pr-1 w-2/3">{subject}</h1>
        <div className="w-1/3 text-sm  font-medium flex flex-row flex-wrap justify-end items-center">
          <div className="mx-1">Status</div>
          <div className="bg-gray-400 px-4 py-1 rounded text-ogWhite ">
            {status}
          </div>
        </div>
      </div>
      {error && <ErrorText error={true} text={error} styles={{paddingTop:"1rem",paddingBottom:"1rem"}} />}
      <div className="flex flex-row items-center">
        <div className="rotate-45">
          <BookOnlineIcon className="text-bluishGrey" />
        </div>
        <div className="ml-2 text-sm font-medium">#{ticketId}</div>
      </div>
    </section>
  );
};

export default TicketSummary;
