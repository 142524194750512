import { createSlice } from "@reduxjs/toolkit";
import getFilteredData from "./getFilteredData";

const ticketsSlice = createSlice({
    name: "ticketsList",
    initialState: {
      unfilteredData: { firstBatch: [], moreBatches: [] },
      allFilters: { status: "All Open", view: "Organization Tickets", search: "" },
      newData: {
        data: [],
        batchURL:
          "https://api.hubapi.com/crm/v3/objects/tickets?limit=100&properties=subject%2Chubspot_owner_id%2Ccreatedate%2Chs_pipeline_stage&associations=companies,contacts,conversations",
      },
      filteredData: [],
      hubSpotNextBatchId: "0",
      loadingState: { text: "Loading Tickets", show: true, showCircle: true },
      // ownerId : '',
      companyId: "",
      userId: "",
      page: 0,
      activeCategoryIndex : 0
    },
    reducers: {
      collectData(state, action) {
        if (action.payload["batchURL"] !== state.newData.batchURL) {
          state.newData.data = [...state.newData.data, ...action.payload["data"]];
        }
        state.newData.batchURL = action.payload["batchURL"];
      },
  
      setFilters(state, action) {
        // This reducer will take filter values and then apply filter on data.
        const filterName = Object.keys(action.payload)[0];
        state.allFilters[filterName] = action.payload[filterName]; // Updating whichver filter is given
  
        state.page = 0;
        state.filteredData = getFilteredData(
          state.newData.data,
          state.allFilters.status,
          state.allFilters.view,
          state.allFilters.search,
          state.companyId,
          state.userId
        );
      },
  
      setNextBatchId(state, action) {
        state.hubSpotNextBatchId = action.payload;
      },
  
      runFilters(state) {
        // Unlike 'setFilters' this reducer wont take any filter values but will apply filters using the existing filter states.
        // This reducer will be used in 'TicketsPage' inside useEffect to apply filters on each app load
        state.page = 0;
        state.filteredData = getFilteredData(
          state.newData.data,
          state.allFilters.status,
          state.allFilters.view,
          state.allFilters.search,
          state.companyId,
          state.userId
        );
      },
  
      setLoadingState(state, action) {
        state.loadingState = action.payload;
      },
      // setOwnerId (state,action) {
      //   state.ownerId = action.payload
      // },
      setCompIdUserId(state, action) {
        state.companyId = action.payload["compId"];
        state.userId = action.payload["userId"];
      },
      setTablePage(state, action) {
        state.page = action.payload;
      },
      setActiveCategoryIndex (state,action) {
        state.activeCategoryIndex = action.payload
      }
    },
  });

  export default ticketsSlice