import { Fragment } from "react";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/Footer";


const PrivateLayout = (props) => {
    return (
      <Fragment>
        <Header />
        <section className="min-h-screen-3/4">
          {props.children}
        </section>
        <Footer />
      </Fragment>
    );
  };
  
  export default PrivateLayout;