// INFO : This function will be used to download pdf file url from Dropbox :
import prepareAPIHeaders from "./prepareAPIHeaders";

const fetchFileURL_DBX = async (accessToken, file, callback) => {
  // Dropbox API call function ==>
  const myHeaders = prepareAPIHeaders(accessToken);
  myHeaders.append("Dropbox-API-Arg", `{"path": "${file.path_lower}"}`);
  myHeaders.delete("Content-Type");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      "https://content.dropboxapi.com/2/files/download",
      requestOptions
    );
    const data = await response.blob();
    const blobData = new Blob([data], { type: "application/pdf" });
    const url = URL.createObjectURL(blobData);

    callback({ ...file, downloadURL: url });
  } catch (err) {
    console.log("Error while downloading file : ", file.name, err.message);
    callback({ ...file, downloadURL: "" });
  }
};

// const fetchFiles = async (fileArray, accessToken, callbackFun) => {
//   for (const file of fileArray) {
//     await fetchFileURL_DBX(accessToken, file, callbackFun);
//   }
// };

const fetchFileURLs = async (fileArray, accessToken) => {
  return new Promise((resolve, reject) => {
    fileArray.length === 0 && resolve(fileArray);

    const fileArrayWithURLs = [];
    const callbackFunc = (result) => {
      fileArrayWithURLs.push(result);
      if (fileArrayWithURLs.length === fileArray.length) {
        resolve(fileArrayWithURLs);
      }
    };

    // fetchFiles(fileArray, accessToken, callbackFunc);

    fileArray.forEach(file => fetchFileURL_DBX(accessToken,file,callbackFunc));
  });
};

export default fetchFileURLs;
