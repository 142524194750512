// INFO : This component will be rendered if no valid routes are matched.

const ErrorPage = () => {
  return (
    <div id="error-page" className="text-center flex flex-col justify-center h-screen-3/4">
      <div>
      <h1 className="text-8xl font-bold my-auto">404</h1>
      <p className="text-2xl">This page is not found.</p>

      </div>
    </div>
  );
};

export default ErrorPage;
