// INFO : This API function will take an email and will search its contact details with Contact API.
// If successfully found email in contact data, this API will return Firstname, lastname and email id
// NOTE : This data is required when with Owners API, we can not find user's userid which means logged-in user is external client

const fetchContactDetails = async (email, callback) => {
  try {
    const response = await fetch(process.env.REACT_APP_SEARCHCONTACT, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email }),
    });
    const responseData = await response.json();
    // console.log("Search Contact Response Data ", responseData);
    if (responseData.status !== "OK") {
      callback("N/A");
    } else {
      callback(
        responseData.contactData.firstName === 'null'
          ? "N/A"
          : responseData.contactData.firstName
      );
    }
  } catch (error) {
    console.log("Error while searching contact : ", error.message);
  }
};

export default fetchContactDetails;
