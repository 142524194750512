import { Fragment, useRef, useState, memo, useEffect } from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import EmptyCard from "./empty-report-card";
import { useSelector } from "react-redux";

const ReportsCarousel = ({ reportsData, companyName }) => {
  const [slideDirection, setSlideDirection] = useState("left");
  const [maxSlides, setMaxSlides] = useState(0);
  const [slidesRange, setSlideRange] = useState({ start: 0, end: 0 });
  const mainRef = useRef();
  const companyNameFormatted = useSelector(
    (state) => state.reports.companyDetails.formattedName
  );

  useEffect(() => {
    const screenSize = window.screen.width * (11 / 12);
    // Coefficient based on media queries
    const slideWidthCoeff =
      screenSize <= 320
        ? 0.6
        : screenSize <= 460
        ? 0.7
        : screenSize <= 768
        ? 0.8
        : 1;
    // 296 = 288 ( slide width) + 8 ( margin)
    const maxS = Math.floor(screenSize / (296 * slideWidthCoeff));
    console.log("Max Slides : ", maxS);
    setMaxSlides(maxS);
    setSlideRange({ start: 0, end: maxS });
  }, []);

  const ReportCard = ({ slideDirection, slideNumber, file, index }) => {
    const date = new Date(file.client_modified);
    let quarterTag = "";
    

    quarterTag = file?.tags?.filter(
      (tag) => ![companyNameFormatted, "brand_guidelines"]?.includes(tag)
    );
    quarterTag = quarterTag?.length
      ? quarterTag?.[0]?.replaceAll("_", " ")?.toUpperCase()
      : "";

    return (
      <Slide
        in={true}
        direction={slideDirection}
        timeout={{ enter: 1200 }}
        container={mainRef.current && mainRef.current.children[slideNumber]}
      >
        <Box className=" bg-ogWhite h-48 rounded-lg text-black1 flex flex-col md:flex-row md:mr-4 shrink-0 shadow-custom">
          <div
            className={`w-full h-2 md:h-full  md:w-3 rounded-t-lg md:rounded-r-none md:rounded-l-lg ${
              index === 0 ? "bg-orange-500" : "bg-gray-500"
            }`}
          ></div>
          <div className="p-2">
            <div className="font-semibold capitalize h-8">{quarterTag}</div>
            <p
              className="mt-2 text-ellipsis text-sm h-14 overflow-auto font-roboto"
              style={{ textTransform: "capitalize" }}
            >
              {file?.name?.replaceAll("_", " ").split(".")[0]}
            </p>
            <p className="my-2 text-sm font-roboto">
              {date.toDateString().split(" ").slice(1).join(" ")}
            </p>
            <div className="w-full border-t border-gray-700"></div>
            <div className="flex flex-row items-center mt-2 hover:text-orange-500">
              <AssessmentIcon />
              <a
                className="ml-2 text-sm font-semibold"
                href={file.downloadURL}
                download={file.name}
              >
                View Report
              </a>
            </div>
          </div>
        </Box>
      </Slide>
    );
  };

  const reportsArray = reportsData.map((file, index) => {
    return (slideNumber) => (
      <ReportCard
        slideNumber={slideNumber}
        slideDirection={slideDirection}
        file={file}
        index={index}
      />
    );
  });

  const incrementSlideHandler = () => {
    setSlideDirection("left");
    const startSlide = slidesRange.start + 1;
    const endSlide = startSlide + maxSlides;

    if (endSlide <= reportsData.length) {
      setSlideRange({ start: startSlide, end: endSlide });
    }
  };

  const decrementSlideHandler = () => {
    setSlideDirection("right");

    const startSlide = slidesRange.start - 1;
    const endSlide = startSlide + maxSlides;

    if (startSlide >= 0) {
      setSlideRange({ start: startSlide, end: endSlide });
    }
  };

  return (
    <Fragment>
      <div>
        {reportsArray.length === 0 ? (
          <div className="">
            <EmptyCard />
          </div>
        ) : (
          <div className="relative h-70">
            <div
              className="flex flex-row w-full justify-start overflow-x-hidden pb-5 pt-1 px-2 "
              ref={mainRef}
            >
              {reportsArray
                .slice(slidesRange.start, slidesRange.end + 1)
                .map((slide, index) => (
                  <div className="w-72 shrink-0 mr-2" key={index}>
                    {slide(index)}
                  </div>
                ))}
            </div>
            {slidesRange.start > 0 && (
              <div className="absolute left-0 top-[40%] ">
                <IconButton
                  className=" bg-ogWhite  w-1/6 h-1/6 "
                  onClick={decrementSlideHandler}
                >
                  <ChevronLeftRoundedIcon className="bg-ogWhite rounded-full shadow-custom" />
                </IconButton>
              </div>
            )}
            {reportsData.length > maxSlides &&
              slidesRange.end !== reportsData.length && (
                <div className="absolute -right-8 top-[40%] ">
                  <IconButton
                    className=" bg-ogWhite rounded-full w-1/6 h-1/6 "
                    onClick={incrementSlideHandler}
                  >
                    <ChevronRightRoundedIcon className="bg-ogWhite rounded-full shadow-custom" />
                  </IconButton>
                </div>
              )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default memo(ReportsCarousel);
