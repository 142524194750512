import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { ticketsActions } from "../../../store/redux-index";

const ViewFilter = () => {
  const filterState = useSelector((state) => state.tickets.allFilters.view);
  const dispatch = useDispatch();

  const onChangeHandler = (event) => {
    dispatch(ticketsActions.setFilters({ view: event.target.value }));
  };
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <Select
        value={filterState}
        onChange={onChangeHandler}
        displayEmpty
        size="small"
        className="bg-ogWhite"
        sx={{ fontSize: "0.875rem",color:'black' }}
      >
        <MenuItem value={"My Tickets"} sx={{ fontSize: "0.875rem" ,color:'black'}}>
          My Tickets
        </MenuItem>
        <MenuItem value={"Organization Tickets"} sx={{ fontSize: "0.875rem",color:'black' }}>
          Organization Tickets
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ViewFilter;
