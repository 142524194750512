import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ticketsActions } from "../../../store/redux-index";
import { useSelector, useDispatch } from "react-redux";
import { stagingStatusMap, prodStatusMap } from "../../../pages/statusMap";

const StatusFilter = () => {
  const filterState = useSelector((state) => state.tickets.allFilters.status);
  const dispatch = useDispatch();

  const ticketStatusMap =
    process.env.REACT_APP_STATUSMAP === "staging"
      ? stagingStatusMap
      : prodStatusMap;

  const onChangeHandler = (event) => {
    dispatch(ticketsActions.setFilters({ status: event.target.value }));
  };
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <Select
        value={filterState}
        onChange={onChangeHandler}
        displayEmpty
        size="small"
        className="bg-ogWhite"
        sx={{ fontSize: "0.875rem" ,color:'black'}}
      >
        <MenuItem value={"All Open"} sx={{ fontSize: "0.875rem" }}>
          All Open
        </MenuItem>
        <MenuItem value={"All"} sx={{ fontSize: "0.875rem" }}>
          All
        </MenuItem>
        {Object.keys(ticketStatusMap).map((id, index) => (
          <MenuItem
            key={index}
            value={ticketStatusMap[id]}
            sx={{ fontSize: "0.875rem" ,color:'black'}}
          >
            {ticketStatusMap[id]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
