// INFO : This component is responsible for making API call to dropbox for fetching reports data

import fetchFileURLs from "./fetchFileURLs";
import getAccessToken from "./getAccessToken";
import prepareAPIHeaders from "./prepareAPIHeaders";
import reportsParser from "./reportsParser";

const fetchReportsDBX = (accessToken) => {
  return new Promise(async (resolve, reject) => {
    const myHeaders = prepareAPIHeaders(accessToken);
    var raw = JSON.stringify({
      include_deleted: false,
      include_has_explicit_shared_members: false,
      include_media_info: false,
      include_mounted_folders: true,
      include_non_downloadable_files: true,
      path: "/mw team folder/mw data reporting",
      recursive: true,
    });
    // "path": "/mw team folder/mw data reporting",
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.dropboxapi.com/2/files/list_folder",
        requestOptions
      );
      const responsBody = await response.json();

      resolve({ error: null, data: responsBody });
    } catch (err) {
      console.log("Error while fetching reports : ", err.message);
      reject({ error: err.message });
    }
  });
};

const fetchReports = async (
  setReportsData,
  companyId,
  dispatch,
  reportsActions,
  companyNameFormatted
) => {
  let reports;
  let accessToken;
  dispatch(reportsActions.setReportsLoading(true));
  accessToken = await getAccessToken(false);

  reports = await fetchReportsDBX(accessToken.token);
  if (reports.error === null && !reports.data.error_summary) {
    // we return this data
    // console.log(reports);
  } else if (reports.data.error_summary?.includes("expired_access_token")) {
    // We call
    // console.log("We call function again");
    accessToken = await getAccessToken(true);
    // console.log("Fetching Token ...", accessToken);
    reports = await fetchReportsDBX(accessToken.token);
  } else {
    // We update the error on UI
    console.log("We encoutnered error", reports);
  }

  const filteredData = await reportsParser(
    reports.data.entries,
    companyNameFormatted,
    accessToken.token
  );
  // console.log("Filtereda +> ", filteredData);
  const dataWithDownloadURLs = await fetchFileURLs(
    filteredData,
    accessToken.token
  );
  // console.log("dataWithDownloadURLs +> ", dataWithDownloadURLs);
  // Sorted Data in Descending order of modified date : 
  const sortedData = dataWithDownloadURLs.sort((x, y) =>
    new Date(y.client_modified) > new Date(x.client_modified) ? 1 : -1
  );
  setReportsData(sortedData);
  dispatch(reportsActions.setReportsLoading(false));
};

export default fetchReports;
