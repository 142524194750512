import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import {useNavigate  } from "react-router-dom";
import { Fragment } from "react";
import LoaderText from "../../utils/LoaderText";
import TicketsPaginationUtil from "./TicketsPaginationUtil";
import { useSelector, useDispatch } from "react-redux";
import { ticketsActions } from "../../../store/redux-index";

const headersArray = ["SUBJECT", "CREATED", "LAST ACTIVITY", "STATUS"];

const TicketsTable = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rows = useSelector((state) => state.tickets.filteredData);
  const loadingState = useSelector((state) => state.tickets.loadingState);
  const page = useSelector((state) => state.tickets.page);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    dispatch(ticketsActions.setTablePage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(ticketsActions.setTablePage(0));
  };

  // Handler to open thread-details-page :
  const handleOpenThread = (ticket) => {
    navigate(`/details?threadId=${ticket.threadId}&ticketId=${ticket.id}`)

  };

  // -------------------------------------------------------------------
  // MAIN SECTION ==>
  // -------------------------------------------------------------------
  return (
    <Fragment>
      <TableContainer className="min-h-160px" component={Paper}>
        <Table
          //   stickyHeader
          //   aria-label="sticky table"
          sx={{ minWidth: 650 }}
          className="border-borderGrey border"
          aria-label="custom pagination table"
        >
          <TableHead className="bg-lightGrey">
            <TableRow>
              {headersArray.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                  // align="center"
                >
                  <div className="text-black">{item}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loadingState.show && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <LoaderText
                    text={loadingState.text}
                    shouldDisplay={loadingState.show}
                    showCircle={loadingState.showCircle}
                    styles={{
                      margin: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                      fontWeight: 400,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!loadingState.show && (
            <TableBody>
              {rows.length ? (
                (rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, index) => (
                  <TableRow
                    key={index}
                    className="cursor-pointer hover:bg-lightGrey !text-black1"
                    onClick={handleOpenThread.bind(null, row)}
                  >
                    <TableCell
                      style={{ width: "40%" }}
                      // align="center"
                      padding="none"
                    >
                      <div className="p-4 text-black">{row.subject}</div>
                    </TableCell>
                    <TableCell
                      style={{ width: "20%" }}
                      // align="center"
                      padding="none"
                    >
                      <div className="p-4 text-black">{row.created}</div>
                    </TableCell>
                    <TableCell
                      style={{ width: "20%" }}
                      // align="center"
                      padding="none"
                    >
                      <div className="p-4 text-black">{row.lastActive}</div>
                    </TableCell>
                    <TableCell
                      style={{ width: "20%" }}
                      // align="center"
                      padding="none"
                    >
                      <div className="p-4 text-black">{row.status}</div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center" sx={{color:'black'}}>
                    No tickets associated. Please try changing your VIEW above
                    to My Organization. If this seems to be an error contact
                    support@marketingwiz.co.
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TableContainer>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5,25, 50, 75, { label: "All", value: -1 }]}
                colSpan={5}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TicketsPaginationUtil}
                sx={{color:'black'}}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default TicketsTable;
