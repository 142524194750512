import { useSelector } from "react-redux";
import getNavList from "./getNavList";

const Footer = () => {
  const brandURL = useSelector(
    (state) => state.reports.companyDetails.has_brand_url
  );
  const fileUploadURL = useSelector(
    (state) => state.reports.companyDetails.portal_upload_file_nav
  );
  return (
    <footer className="bg-footerWhite pt-8 ">
      <nav className="flex flex-row flex-wrap justify-center items-center headerLinks my-8">
        {getNavList(brandURL, fileUploadURL).map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </nav>
      <div className="text-center mb-4">
        Copyright 2023-24 All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
