
const NavigationDesktop = ({ navList }) => {
  return (
    <nav className="hidden md:flex flex-row headerLinks">
      {navList.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </nav>
  );
};

export default NavigationDesktop;
