export const requestText = `Use the box below to provide a high level summary of your project (target audience, topic, solution offerings & main message you wish to impart), What actions do you want the audience to take and then any other specific instructions or requests for the project.`;

export const openAIEndpoint = "https://api.openai.com/v1/chat/completions";

const generateSubjectPrompt =
  "You're a world class project manager working for a service delivery firm in the RIA space. Your job is to create a project title that can be unique and added onto the name of a projection creation ticket. Below is an overview of the project, in the client's words, put together a concise 10 word or less subject line that summarizes what is being asked for.";

/**
 * @description This header is to generate subject line via ChatGPT that we will use further to create ticket in Hubspot
 */
export const requestBody_GenerateSubject = (content) => {
  return JSON.stringify({
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "user",
        content: generateSubjectPrompt + " " + content,
      },
    ],
    temperature: 0.7,
  });
};
export const requestBody_GenerateResponse = (content) => {
  return JSON.stringify({
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "user",
        content: content,
      },
    ],
    temperature: 0.7,
  });
};

export const getHeader = () => {
  const headers = new Headers();
  headers.append("Authorization", "Bearer " + process.env.REACT_APP_OPENAI_TKN);
  headers.append("Content-Type", "application/json");

  return headers;
};

export const defaultPlaceholderText = "Share your thoughts here.";

export const loaderText =
  "Please wait while we submit the request to our OpenAI engine... this takes up to 30 seconds so patience is key!";

export const loaderTextArray = [
  "AI finalizing summarization...",
  "If anything arises that you need assistance on, please respond in the ticket thread...",
  "Your requests will be assigned to a project manager and available in the Portal...",
  "Our service delivery team will be taking this AI generated request when completed...",
  "This can take up to 30 seconds given the billions of parameters being used...",
  "Our platform is submitting your request to the OpenAI engine... please be patient...",
];

export const parseSubjectString = (string, maxChars = 10) => {
  if (typeof string !== "string") return "";

  let subject;
  // Step # 1 : Remove the extra quotes received from Chat GPT :
  subject = string?.replaceAll('"', "");

  // Step # 2 : Limit subject string to max 10 chars
  // This is required in case Chat GPT returns a larger string

  subject = subject?.split(" ")?.slice(0, maxChars)?.join(" ");

  // Step # 3 : Remove punctuation (.) if present at the end of string
  subject = subject?.replaceAll(".", "");

  return subject;
};

export const fileUploadText =
  "Please send us any attachments you would like for us to review as part of your request.";

export const prepareTicketFormData = (
  rawFormData,
  fileURLArray,
  hs_context,
  promptCategory = ""
) => {
  const data = [
    {
      objectTypeId: "0-1",
      name: "email",
      value: rawFormData[2],
    },
    {
      objectTypeId: "0-5",
      name: "content",
      value: rawFormData[4],
    },
    {
      objectTypeId: "0-5",
      name: "subject",
      value: rawFormData[3],
    },
    {
      objectTypeId: "0-5",
      name: "prompt_category",
      value: promptCategory,
    },
  ];

  // Conditionally push firstname and lastname as these fields are not present for authenticated user in AI ticket :
  if (rawFormData[0]) {
    data.push({
      objectTypeId: "0-1",
      name: "firstname",
      value: rawFormData[0],
    });
  }
  if (rawFormData[1]) {
    data.push({
      objectTypeId: "0-1",
      name: "lastname",
      value: rawFormData[1],
    });
  }

  fileURLArray.forEach((url) =>
    data.push({
      objectTypeId: "0-5",
      name: "hs_file_upload",
      value: url,
    })
  );

  console.log("payleod ", data);
  return JSON.stringify({ fields: data, context: hs_context });
};

// Map to hold categories as shown in the carousel tab
export const categoriesMap = [
  {
    label: "Email (Single)",
    imgSrc: "./ticketCategoriesIcons/emailSingle.svg",
    locked: false,
    value: `You are going to receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client directly. Your job is to take outline format with the client input, which is labelled underneath Client Input Below, and create a complete output that follows the directions in the format.
      The sections we want to provide in the output are:
      - Project Title
      - Overview
      - Campaign Strategy
      - Draft Email
      - Initial Task List
      To generate the Overview, act as if I’m a financial advisor submitting a project to my financial marketing agency to produce and deliver an email campaign. Write a project description that would be used in the new project creation stage via my agencies client portal. This description should be a 2 to 3 sentence summary that explains the project, who it targets and the required deliverables. Assume as a financial advisor I help investors with financial planning, comprehensive wealth management solutions, investment management solutions and that our firm is a Registered Investment Advisory firm operating under the Fiduciary ruling. In the output do not referencing Registered Investment Advisory firm or RIA. Only reference Fiduciary if appropriate based on the input but do not over use or over state the term Fiduciary in the output.
      To generate a project title that the overview information and create a concise project title that incorporates the tag email so that the agency understands that this is an email creation project.
      To develop a campaign strategy act as if I’m the account manager of a financial marketing agency and our client has just submitted a request that we need to create a high level campaign summary to play back to the client and to share with our production team to align around project goals and expectations. The request is the overview that was created previously in this prompt. The strategy should be drafted as a concise paragraph with 3 to 5 bullet points that make it clear how this email will be positioned to engage our client’s (a Financial Advisor) clients or prospects. If the request mentions the target audience or investor persona please incorporate that detail. If the target audience or investor persona is unclear then include a note in the campaign strategy to ensure that we (the agency) aligns with the client (the financial advisor) on who exactly we are targeting.
      Draft Email
      Act as if I’m a content writer working for a financial marketing agency and our client has requested that we draft an email to be sent to their client or prospect. This email should be concise and use an educational or academic tone while being personable and engaging. This email should be written based on the overview created using the overview and campaign strategy outputed above. In this output include 2 subject lines and 2 options of preview text as well as two versions of the email consideration. The second email should rewrite the first email using a more informal and personal tone that would feel like a human advisor who is sitting down to draft a quick email to a prospect or client.
      To Do List
      Take all the above outputs and prepare a list of to do items that will be used to setup a project and assign to the delivery team members at our financial marketing firm. Each todo item should have a 1 to sentence task description.  To successfully deliver this project we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated to for internal review. Once the content has been reviewed and approved internally it will be sent to the client for review and approvals. Once our client approves we will complete the set up and schedule it for distribution.
  Client Input Below`,
  },
  {
    label: "Email Sequence",
    imgSrc: "./ticketCategoriesIcons/emailSequence.svg",
    locked: false,
    value: `As a the lead for a marketing campaign, you are tasked with devising a comprehensive email campaign strategy for clients in the Registered Investment Advisor (RIA) market. The campaign will focus on upstream sales, new client conversations, and overall engagement. Utilize the following outline to create a detailed plan, incorporating the client feedback and understanding the psychological factors that drive their target audience.
    Background:
    Use the client feedback to develop a solid understanding of their specific needs and requirements, along with any relevant psychological factors that may impact their target audience's decision-making process.
    Campaign Strategy:
    Devise an overarching strategy that effectively addresses the client's objectives and resonates with their target audience. Ensure that the campaign delivers a clear and compelling message that aligns with the clients' goals.
    Campaign Channels:
    Focusing exclusively on email as the primary communication channel, detail the approach to optimize open rates, click-through rates, and engagement.
    Draft Email Starter:
    Craft an engaging email that incorporates the client feedback and sets up a framework for the delivery team to build upon. Ensure that the message is concise, relevant, and resonates with the target audience.
    Draft Follow-up Email:
    Develop a powerful follow-up email that encourages recipients to take the next step in the engagement process, such as scheduling a phone call or booking a consultation.
    Client Feedback:`,
  },
  {
    label: "Articles",
    imgSrc: "./ticketCategoriesIcons/articles.svg",
    locked: false,
    value: `You will receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client. Your job is to take the outline format with the client input, which is labeled underneath Client Input Below, and create a complete output that follows the directions in the format.
    The sections we want to provide in the output are:
        •	Article Title
        •	Overview
        •	Article Structure
        •	Draft Article
        •	Initial Task List
    To generate the Overview, act as if I'm a marketing delivery firm specializing in RIA support and creating this for a client to produce and deliver a set of articles. Write a project description that would be used in the new project creation stage via the agency's client portal. This description should be a 2 to 3 sentence summary that explains the article topic, who it targets, and the required deliverables. Assume as a marketing firm specific for RIAs, we focus on the financial world for client's and how the RIA is a thought leader and a trusted advisor for their wealth management.
    To generate an article title, take the overview information and create a concise article title that clearly reflects the topic of the article.
    To develop an article structure, act as if I'm the editor of a financial publication like Bloomberg and our client has just submitted a request for an article. The request is the overview that was created previously in this prompt. The structure should be drafted as a concise paragraph with 3 to 5 bullet points that make it clear how this article will be structured to engage our publication's readers. If the request mentions the target audience or reader persona, please incorporate that detail. If the target audience or reader persona is unclear, then include a note in the article structure to ensure that we align with the client on who exactly we are targeting.
    Draft Article
    Act as if I'm a content writer working for a financial publication like Bloomberg and our client has requested that we draft an article for publication. This article should be informative and engaging while being on-topic with the client's mission. This article should be written based on the overview and article structure created above. In this output, include the complete article with a word count of between 500-800 words.
    To Do List
    Take all the above outputs and prepare a list of to-do items that will be used to set up a project and assign it to the delivery team members at our content agency. Each to-do item should have a 1 to sentence task description. To successfully deliver this project, we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated for internal review. Once the content has been reviewed and approved internally, it will be sent to the client for review and approvals. Once our client approves, we will complete the set up and publish the article on our publication.`,
  },
  {
    label: "Social Media",
    imgSrc: "./ticketCategoriesIcons/socialMedia.svg",
    locked: false,
    value: `You are going to receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client directly as the final paragraph in this prompt. Your job is to take the outline format with the client input, which is labelled underneath Client Input Below, and create a complete output that follows the directions in the format. You are going to be given limited client input so your job is to fill in the blank for a complete outline.
    The sections we want to provide in the output are:
        •	Campaign Title
        •	Overview
        •	Campaign Strategy
        •	Draft Social Media Posts
        •	Initial Task List
    To generate the Overview, act as if you are submitting a project summary to a social media agency to produce and deliver a social media campaign. Write a project description that would be used in the new project creation stage. This description should be a 2 to 3 sentence summary that explains the campaign, who it targets and the required deliverables. Assume as a social media project manager, you specialize in financial and economic thought leadership which is good enough for the top tier outlets. In the output, this needs to be clear and concise.
    To generate a campaign title, take the overview information and create a concise campaign title that incorporates the tag social media so that the agency understands that this is a social media campaign.
    To develop a campaign strategy, act as if you're a leading visionary in social media and our client has just submitted a request that we need to create a high level campaign summary to play back to the client and to share with our production team to align around project goals and expectations. This has to be compelling and innovative. The request is the overview that was created previously in this prompt. The strategy should be drafted as a concise paragraph with 3 to 5 bullet points that make it clear how this social media campaign will be positioned to engage our client's target audience. If the request mentions the target audience or influencer persona, please incorporate that detail. If the target audience or influencer persona is unclear, then include a note in the campaign strategy to ensure that we (the agency) aligns with the client (the influencer) on who exactly we are targeting.
    To generate the draft Social Media Posts act as if you're a content writer working for a social media agency and our client has requested that we draft social media posts to be shared on their social media accounts. These posts should be concise and use a friendly and engaging tone while being on-brand with the influencer's content. These posts should be written based on the overview and campaign strategy created above. In this output, include 2 options of captions and hashtags for each social media platform the influencer will be posting on (e.g. Instagram, Facebook, LinkedIn, Twitter). The strategy previously will determine what platforms are best. Do not utilize TikTok.
    To generate a To Do List, take all the above outputs and prepare a list of to-do items that will be used to set up a project and assign it to the delivery team members at our social media agency. Each to-do item should have a 1 to sentence task description. To successfully deliver this project, we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated for internal review. Once the content has been reviewed and approved internally, it will be sent to the client for review and approvals. Once our client approves, we will complete the set up and schedule it for distribution.`,
  },
  {
    label: "3rd Party Articles",
    imgSrc: "./ticketCategoriesIcons/thirdParty.svg",
    locked: true,
    value: `You are going to receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client directly. Your job is to take the outline format with the client input, which is labeled underneath Client Input Below, and create a complete output that follows the directions in the format.
        The sections we want to provide in the output are:
            •	Article Title
            •	Overview
            •	Article Structure
            •	Draft Article
            •	Initial Task List
        To generate the Overview, act as if I’m a business owner submitting a project to a content agency to produce and deliver a set of articles. Write a project description that would be used in the new project creation stage via the agency's client portal. This description should be a 2 to 3 sentence summary that explains the article topic, who it targets and the required deliverables. Assume as a business owner, I run a tech startup that develops mobile applications for small businesses. In the output, do not reference the business name or industry.
        To generate an article title, take the overview information and create a concise article title that clearly reflects the topic of the article.
        To develop an article structure, act as if I’m the editor of a business publication and our client has just submitted a request for an article. The request is the overview that was created previously in this prompt. The structure should be drafted as a concise paragraph with 3 to 5 bullet points that make it clear how this article will be structured to engage our publication's readers. If the request mentions the target audience or reader persona, please incorporate that detail. If the target audience or reader persona is unclear, then include a note in the article structure to ensure that we align with the client on who exactly we are targeting.
        Draft Article
        Act as if I’m a content writer working for a business publication and our client has requested that we draft an article for publication. This article should be informative and engaging while being on-topic with the client's business. This article should be written based on the overview and article structure created above. In this output, include the complete article with a word count of between 500-800 words.
        To Do List
        Take all the above outputs and prepare a list of to-do items that will be used to set up a project and assign it to the delivery team members at our content agency. Each to-do item should have a 1 to sentence task description. To successfully deliver this project, we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated for internal review. Once the content has been reviewed and approved internally, it will be sent to the client for review and approvals. Once our client approves, we will complete the set up and publish the article on our publication.`,
  },
  {
    label: "Press Releases",
    imgSrc: "./ticketCategoriesIcons/press.svg",
    locked: true,
    value: "",
  },
  {
    label: "Market Commentaries",
    imgSrc: "./ticketCategoriesIcons/marketing.svg",
    locked: true,
    value: "",
  },
  {
    label: "Digital Ads",
    imgSrc: "./ticketCategoriesIcons/digital.svg",
    locked: true,
    value: "",
  },
  {
    label: "Webinars",
    imgSrc: "./ticketCategoriesIcons/webinar.svg",
    locked: true,
    value: `You will receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client. Your job is to take the outline format with the client input, which is labeled underneath Client Input Below, and create a complete output that follows the directions in the format.
        The sections we want to provide in the output are:
            •	Webinar Title
            •	Overview
            •	Agenda
            •	Presentation Slides
            •	Speaker Bios
            •	Initial Task List
        To generate the Overview, act as if I’m a marketing director of a software company submitting a project to a consulting firm to produce and deliver a webinar. Write a project description that would be used in the new project creation stage via the consulting firm's client portal. This description should be a 2 to 3 sentence summary that explains the topic of the webinar, who it targets and the required deliverables. Assume as a marketing director, I want to host a webinar on the topic of AI in software development and its benefits. In the output, do not reference the company name or specific industry.
        To generate a Webinar Title, take the overview information and create a concise title that clearly reflects the topic of the webinar.
        To develop an Agenda, act as if I’m the producer of the webinar and our client has just submitted a request for a webinar. The request is the overview that was created previously in this prompt. The Agenda should be a concise list of the topics that will be covered in the webinar, including the time allotted for each topic.
        To develop the Presentation Slides, act as if I’m a content developer working for the consulting firm and our client has requested that we produce the presentation slides for the webinar on the topic of AI in software development and its benefits. This section should be written based on the overview created above and should provide a detailed analysis of the topic, including relevant data and case studies.
        To develop the Speaker Bios, provide brief biographies of the speakers who will be presenting at the webinar, including their expertise and experience in the field of AI and software development.
        To Do List
        Take all the above outputs and prepare a list of to-do items that will be used to set up a project and assign it to the delivery team members at our consulting firm. Each to-do item should have a 1 to sentence task description. To successfully deliver this project, we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated for internal review. Once the content has been reviewed and approved internally, we will create the webinar registration page and promotional materials. We will also conduct a technical rehearsal to ensure that the webinar runs smoothly. On the day of the webinar, we will manage the webinar logistics, including the live broadcast and Q&A session. After the webinar, we will provide the client with a recording of the webinar and a report on the webinar's performance.`,
  },
  {
    label: "White Papers",
    imgSrc: "./ticketCategoriesIcons/whitePaper.svg",
    locked: true,
    value: `You will receive a clear outline of what we're looking to accomplish below and then a specific set of input from the client. Your job is to take the outline format with the client input, which is labeled underneath Client Input Below, and create a complete output that follows the directions in the format.
        The sections we want to provide in the output are:
            •	White Paper Title
            •	Overview
            •	Executive Summary
            •	Main Content
            •	Conclusion
            •	Initial Task List
        To generate the Overview, act as if I’m a CEO of a technology company submitting a project to a consulting firm to produce and deliver a white paper. Write a project description that would be used in the new project creation stage via the consulting firm's client portal. This description should be a 2 to 3 sentence summary that explains the topic of the white paper, who it targets and the required deliverables. Assume as a CEO, I want to explore the impact of artificial intelligence on business operations and productivity. In the output, do not reference the company name or specific industry.
        To generate a White Paper Title, take the overview information and create a concise title that clearly reflects the topic of the white paper.
        To develop an Executive Summary, act as if I’m the editor of a business publication and our client has just submitted a request for a white paper. The request is the overview that was created previously in this prompt. The Executive Summary should be a concise paragraph that summarizes the main findings of the white paper and its significance for the business industry.
        To develop the Main Content, act as if I’m a technology researcher working for the consulting firm and our client has requested that we produce a white paper on the impact of artificial intelligence on business operations and productivity. This section should be written based on the overview created above and should provide a detailed analysis of the topic, including relevant data and case studies.
        To develop the Conclusion, summarize the key findings and implications of the white paper for the business industry.
        To Do List
        Take all the above outputs and prepare a list of to-do items that will be used to set up a project and assign it to the delivery team members at our consulting firm. Each to-do item should have a 1 to sentence task description. To successfully deliver this project, we need to review the Chat GPT generated text and revise it for accuracy and alignment with brand voice and specific client deliverables. We then need to insert it into a client review document that is then circulated for internal review. Once the content has been reviewed and approved internally, it will be sent to the client for review and approvals. Once our client approves, we will complete the set up and deliver the white paper to the client in a professional format.`,
  },
  {
    label: "Sales Sheets",
    imgSrc: "./ticketCategoriesIcons/sales.svg",
    locked: true,
    value: "",
  },
  {
    label: "Presentations",
    imgSrc: "./ticketCategoriesIcons/presentations.svg",
    locked: true,
    value: "",
  },
  {
    label: "Schedule an Interview",
    imgSrc: "./ticketCategoriesIcons/interview.svg",
    locked: true,
    value: "",
  },
];
