// This function will accept an email and will return true for valid email and false for invalid email.

const emailValidator = (email) => {
  try {
    // Checking if there are more than one @ in the email
    // Example : 'abc@gmail.com@nwo.org' is invalid email
    if (email.split("@").length !== 2) {
      throw new Error('Expected only one "@"');
    }
    // Checking if there are no dot notations in the email after "@" part
    // Example : 'abc@gmailcome' or 'abc' are invalid emails.
    if (!email.split("@")[1].includes(".")) {
      // console.log(email.split("@"));
      throw new Error("No dot '.' notation found ");
    }
    // Checking if there are any blank parts after "@" with dot notations :
    // Example : 'abc@gmail.co.' is invalid email
    if (email.split("@")[1].split(".").includes("")) {
      // console.log(email.split("@")[1].split("."));
      throw new Error("Invalid blank notation in the end");
    }

    // We will reach here if any of the conditions are not true and do not throw error. 
    return 'valid';
  } catch (err) {
    // console.log(err.message);
    // console.log("Invalid Email");
    return 'invalid';
  }
};

export default emailValidator;
