
const prepareAPIHeaders = (accessToken) => {

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + accessToken);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Dropbox-API-Select-User",
    process.env.REACT_APP_DROPBOX_MEMBERID
  );
  myHeaders.append(
    "Dropbox-API-Path-Root",
    `{".tag": "root", "root": "${process.env.REACT_APP_DROPBOX_FOLDERID}"}`
  );

  return myHeaders;
};

export default prepareAPIHeaders;
