// INFO : This component will work as section wrapper for all the dynamic content cards.

import { useSelector } from "react-redux";
import ContentCard from "./content-card";

const DynamicContent = () => {
  const brandURL = useSelector(
    (state) => state.reports.companyDetails.has_brand_url
  );
  const companyName = useSelector((state) => state.reports.companyDetails.name);

  const cardStylesString = " w-full sm:w-1/2 md:w-1/3 shrink-0";
  return (
    <div className="flex flex-row overflow-x-auto p-1 font-workSans">
      {!!brandURL ? (

          <ContentCard
            styles={cardStylesString}
            Icon={<img src="icons/file-icon.svg" alt="digital-asset" />}
            title="Your Digital Assets"
            details="Brand Guidelines, Logo Files, Fonts, Photography"
            urlLabel="Access Your Brand Dashboard"
            margin="mr-2"
            url={brandURL}
          />
      ) : (
        <ContentCard
          styles={cardStylesString}
          Icon={<img src="icons/file-icon.svg" alt="digital-asset" />}
          title="Start Your Brand Guidelines"
          details={`Learn how brand guidelines can help ${companyName} establish better brand consistency across all online and offline channels.`}
          urlLabel="Request More Information"
          margin="mr-2"
          url="https://share.hsforms.com/1at3rRTL5Sn2iA8qeLkYteg1fobm"
        />
      )}
      <ContentCard
        styles={cardStylesString}
        Icon={<img src="icons/project-icon.svg" alt="your-project" />}
        title="Start Your Next Project"
        details="From content request to website update & much more."
        urlLabel="Get Started"
        margin="ml-2 mr-2"
        url="/create-ticket"
        externalLink={false}
      />
      <ContentCard
        styles={cardStylesString}
        Icon={<img src="icons/reports-icon.svg" alt="reports" />}
        title="Access Your Latest Reports"
        details={`View the latest marketing & business development metrics${companyName && ' for '}${companyName}.`}
        urlLabel="View Reports"
        openNewTab={true}
        margin="ml-2"
        url="/reports"
        externalLink={false}
      />
    </div>
  );
};

export default DynamicContent;
