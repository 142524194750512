// import BrandGuidelines from "./brand-guidelines";
import ReportsCarousel from "./reports-carousel";
import fetchReports from "./dropbox/fetchReports";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reportsActions } from "../../store/redux-index";

const Reports = () => {
  const [reportsData, setReportsData] = useState([]);
  const companyId = useSelector((state) => state.tickets.companyId);
  const companyNameFormatted = useSelector(
    (state) => state.reports.companyDetails.formattedName
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('company id ',companyId)
    companyId &&
      fetchReports(
        setReportsData,
        companyId,
        dispatch,
        reportsActions,
        companyNameFormatted
      );
  }, [companyId, dispatch, companyNameFormatted]);

  return (
    <div>
      <h1 className="text-2xl   md:text-3xl font-sans text-default mb-10">Your Reports</h1>
      <div className="flex flex-col-reverse sm:flex-row md:items-center justify-start">
        <div className="w-full">
          <ReportsCarousel reportsData={reportsData} />
        </div>
        {/* <div className="w-full sm:w-1/3 md:w-1/4">
          <BrandGuidelines reportsData={reportsData}/>
        </div> */}
      </div>
    </div>
  );
};

export default Reports;
// CSS when using brand guidelines card, noting below to use in future :
// flex flex-col-reverse sm:flex-row md:items-center justify-start
//  sm:w-2/3 md:w-3/4
