// INFO : This comp will call Owners API to fetch user id matching it with logged in user

const fetchOwnerId = async (email, callback) => {
  try {
    const response = await fetch(process.env.REACT_APP_GETALLOWNERS, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email }),
    });
    const responseBody = await response.json();
    if (responseBody.status !== "OK") {
      callback(undefined);
    } else {
      callback("A-" + responseBody.ownerId);
    }
  } catch (error) {
    console.log("Error while searching owner id : ", error.message);
  }
};

export default fetchOwnerId;
