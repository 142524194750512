// INFO : Utility component to be used as wrapper, will provide basic styling of shadow, rounder corners etc.
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const ContentCard = ({
  Icon,
  title,
  details,
  urlLabel,
  url,
  externalLink = true,
  margin = "",
  styles = "",
}) => {
  const ExternalLink = ({ url,urlLabel }) => {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-sm  flex flex-row items center py-2 hover:text-mwOrange"
      >
        <p className="mr-2 my-auto font-semibold">{urlLabel}</p>
        <ArrowForwardIcon fontSize="small" />
      </a>
    );
  };

  const InternalLink = ({url, urlLabel }) => {
    return (
      <Link
        to={url}
        className="text-sm  flex flex-row items center py-2 hover:text-mwOrange"
      >
        <p className="mr-2 my-auto font-semibold">{urlLabel}</p>
        <ArrowForwardIcon fontSize="small" />
      </Link>
    );
  };

  return (
    <div className={`${styles}`}>
      <div
        className={`shadow-custom rounded-md p-4 ${margin} bg-ogWhite text-black1`}
      >
        <div className="w-10 h-10 rounded-full bg-veryLightOrange text-mwOrange flex flex-col justify-center items-center">
          {Icon}
        </div>
        <div className="mt-2 h-16 mb-10 ">
          <div className="font-semibold mb-2 text-lg vs:text-base">{title}</div>
          <p className="text-sm font-roboto max-h-16 overflow-auto">
            {details}
          </p>
        </div>
        <div className="border-t-[1px] w-full border-gray-600 "></div>
        {externalLink ? <ExternalLink url={url} urlLabel={urlLabel} /> : <InternalLink url={url} urlLabel={urlLabel}/>}
      </div>
    </div>
  );
};

export default ContentCard;
