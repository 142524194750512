// INFO : This function will fetch tags for each file in the array and then return the array when all tags are fetched

import prepareAPIHeaders from "./prepareAPIHeaders";

const fetchTagsDBX = async (file, accessToken, callback) => {
  let tagsArray = []; // Initialize blank array

  const headers = prepareAPIHeaders(accessToken);
  const raw = JSON.stringify({
    paths: [file.path_lower],
  });

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: raw,
    redirect: "follow",
  };

  // console.log('Checking tags for : ',file.path_lower)

  try {
    const response = await fetch(
      "https://api.dropboxapi.com/2/files/tags/get",
      requestOptions
    );
    const responseBody = await response.json();
    tagsArray = responseBody.paths_to_tags.length
      ? responseBody.paths_to_tags[0].tags.map((x) => x.tag_text)
      : [];

    callback({ ...file, tags: tagsArray });
  } catch (err) {
    console.log(
      "Error while fetching tags for file : ",
      file.name,
      err.message
    );
    callback({ ...file, tags: [] });
  }
};

const fetchTags = async (fileArray, accessToken, callbackFun) => {
  for (const file of fileArray) {
    await fetchTagsDBX(file, accessToken, callbackFun);
  }
};

const fetchFileTags = async (fileArray, accessToken) => {
  return new Promise((resolve, reject) => {
    fileArray.length === 0 && resolve(fileArray);
    const resultArray = [];

    const callbackFun = (result) => {
      resultArray.push(result);
      if (fileArray.length === resultArray.length) {
        resolve(resultArray);
      }
    };

    fetchTags(fileArray, accessToken, callbackFun);
    // fileArray.forEach((file) => fetchTagsDBX(file, accessToken, callbackFun));
  });
};

export default fetchFileTags;
