import { parseString } from "xml2js";


const fetchBlogs = async (setData) => {
    // console.log("Fetching blogs");
    try {
      const response = await fetch(
        "https://info.marketingwiz.co/blogs/rss.xml"
      );
      const responseBody = await response.text();

      parseString(responseBody, function (err, result) {
        // console.dir(result.rss.channel[0].item[0]);

        const parsedData = result.rss.channel[0].item.map((item) => {
          const temp = {};

          const description = item.description[0]
            .split("<p>")[1]
            .split("</p>")[0];
          const imgSrc = item.description[0]
            .split("<img")[1]
            .trim()
            .split(" ")[0]
            .split("=")[1]
            .replaceAll('"', "");

          temp["title"] = item.title[0];
          temp["blogLink"] = item.link[0];
          temp["description"] = description;
          temp["imgSrc"] = imgSrc;

          return temp;
        });

        // console.log("parsed", parsedData);
        setData(parsedData);

        // setData([{title:}])
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  export default fetchBlogs