import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/Homepage";
import TicketsDetailPage from "./pages/TicketsDetailPage";
import CreateTicketPage from "./pages/CreateTicketPage";
import ReportsPage from "./pages/ReportsPage";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ErrorPage from "./pages/ErrorPage";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useEffect } from "react";
import { reportsActions, ticketsActions } from "./store/redux-index";
import getCompanyDetails from "./components/reports/dropbox/getCompanyDetails";
import OtherTicketPage from "./pages/OtherTicketPage";
import PrivateLayout from "./layout/privateLayout";
import PublicLayout from "./layout/publicLayout";
import AppLoader from "./components/utils/Loader";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const App = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCompanyIdAndUserId = async () => {
      dispatch(reportsActions.setCompDetailsLoading(true));
      try {
        const companyResponse = await fetch(
          process.env.REACT_APP_GETCOMPANYID,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: user.email }),
          }
        );

        const companyResponseData = await companyResponse.json();
        if (companyResponseData.status !== "OK")
          throw new Error(companyResponseData.error);
        // const jsonCompData = JSON.parse(companyResponseData);
        // console.log('COMP Data',companyResponseData)

        // Now we will fetch company details :

        const companyDetails = await getCompanyDetails(
          companyResponseData.companyId
        );
        dispatch(reportsActions.setCompanyDetails(companyDetails));

        dispatch(
          ticketsActions.setCompIdUserId({
            compId: companyResponseData.companyId,
            userId: companyResponseData.userId,
          })
        );
        dispatch(reportsActions.setCompDetailsLoading(false));
      } catch (error) {
        console.log(error.message);
        dispatch(reportsActions.setCompDetailsLoading(false));
      }
    };

    Boolean(user?.email) && fetchCompanyIdAndUserId();
  }, [dispatch, user?.email]);

  return (
    <Fragment>
      {isLoading ? (
        <PrivateLayout>
          <Routes>
            <Route path="*" element={<AppLoader />} />
          </Routes>
        </PrivateLayout>
      ) : isAuthenticated ? (
        <PrivateLayout>
          <Routes>
            <Route path="/" element={<ProtectedRoute component={Homepage} />} />
            <Route
              path="/details"
              element={<ProtectedRoute component={TicketsDetailPage} />}
            />
            <Route
              path="/create-ticket"
              element={<ProtectedRoute component={CreateTicketPage} />}
            />
            <Route
              path="/other-ticket"
              element={<ProtectedRoute component={OtherTicketPage} />}
            />
            <Route
              path="/reports"
              element={<ProtectedRoute component={ReportsPage} />}
            />
            <Route path="/AI" element={<Navigate to="/create-ticket" />} />
            <Route path="/ai" element={<Navigate to="/create-ticket" />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </PrivateLayout>
      ) : (
        <PublicLayout>
          <Routes>
            <Route path="*" element={<ProtectedRoute component={Homepage} />} />
            <Route path="/AI" element={<CreateTicketPage />} />
          </Routes>
        </PublicLayout>
      )}
    </Fragment>
  );
};

export default App;
