import { OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useRef, useState } from "react";
import fileUplodWithProxyServer from "../../ticket-details/api-call-functions/fileUploadWithProxyServer";
import ErrorText from "../../utils/ErrorText";
import UploadFileList from "../../utils/UploadFileList";
import {
  fileDeleteHandler,
  fileHandler,
  handleDrag,
  handleDropFile,
} from "../../utils/dragDropFileHandlers";
import emailValidator from "../../utils/emailValidator";
import getUserIPAddress from "../../utils/getUserIPAddress";
import { fileUploadText, prepareTicketFormData } from "../ticketFormUtil";
import SubmitResponse from "./SubmitResponse";

const requestText = (
  <span className="text-xs mb-2">
    Please use this box to provide specific information about your request. At
    this phase we are only looking for a high-level summary of what you need. We
    will contact you if we need more information to complete this request.
  </span>
);

const successText =
  "Your ticket is submitted successfully.\nYou will be able to view this ticket under My Tickets in this dashboard. A member of the Marketing Wiz team will review your request and be in touch shortly.";
const errorText = "Please complete this required field";

const TicketsForm = () => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const subjectRef = useRef();
  const requestDetailsRef = useRef();
  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileNameArray, setFileNameArray] = useState([]);
  const [otherErrors, setOtherErrors] = useState([]);
  const [submitStatus, setSubmitStatus] = useState({
    text: null,
    submitting: false,
    error: null,
  });

  const formDataValidator = () => {
    emailValidator(emailRef.current.value) === "invalid" && setEmailError(true);
    !subjectRef.current.value && setSubjectError(true);
    !requestDetailsRef.current.value && setDetailsError(true);

    emailValidator(emailRef.current.value) === "valid" &&
      subjectRef.current.value &&
      requestDetailsRef.current.value &&
      submitTicket();
  };

  const submitTicket = () => {
    // console.log("Submitting Ticket Form");
    setOtherErrors([]); // Resetting file upload errors
    setSubmitStatus({
      text: "Submitting your ticket | Please wait ...",
      submitting: true,
    });
    let fileUploadCounter = 0;
    const fileURLArray = [];
    const fileUploadErrors = [];
    const filesCount = Object.keys(filesToUpload).length;
    const rawFormData = [
      firstNameRef.current.value,
      lastNameRef.current.value,
      emailRef.current.value,
      subjectRef.current.value,
      requestDetailsRef.current.value,
    ];

    const callbackFunction = (fileResponseData) => {
      if (fileResponseData.status !== "OK") {
        fileUploadErrors.push(
          `File Name : ${fileNameArray[fileUploadCounter].name} | Error : ${fileResponseData.error}`
        );
      } else {
        fileURLArray.push(fileResponseData.url);
      }
      // Updating File Upload Counter
      ++fileUploadCounter;
      if (fileUploadCounter === filesCount) {
        // console.log("All Files Uploaded | Send request to create ticket->");
        // Resetting the files array state ==>
        setFilesToUpload([]);
        setFileNameArray([]);
        submitTicketWithProxyServer(
          rawFormData,
          fileURLArray,
          fileUploadErrors
        );
      }
    };

    //If attachments, we will first upload files, get their ids and then submit API request to create emails
    if (filesCount) {
      // console.log("Calling File Upload");
      Object.keys(filesToUpload).forEach((key) =>
        fileUplodWithProxyServer(filesToUpload[key], callbackFunction)
      );
    } else {
      // If no attachments, then directly submit API request to create emails.
      submitTicketWithProxyServer(rawFormData);
    }
  };

  const submitTicketWithProxyServer = async (
    rawFormData,
    fileURLArray = [],
    fileUploadErrors = []
  ) => {
    const cookies = document.cookie.split("; ");
    let hubspotutk = "";

    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === "hubspotutk") {
        hubspotutk = value;
        break;
      }
    }

    const ip_address = await getUserIPAddress();
    const page_url = window.location.href;
    const page_name = document.title;

    const hs_context = {
      hutk: hubspotutk,
      ipAddress: ip_address,
      pageUri: page_url,
      pageName: page_name,
    };
    try {
      const response = await fetch(process.env.REACT_APP_CREATENEWTICKET, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: prepareTicketFormData(rawFormData, fileURLArray, hs_context),
      });

      const responseData = await response.json();
      if (responseData.status !== "OK") throw new Error(responseData.error);
      setSubmitStatus({
        text: successText,
        submitting: false,
      });
      setOtherErrors(fileUploadErrors);
      // console.log("Ticket Response Data ", responseData);
    } catch (error) {
      // console.log("Error While Ticket Creation ==>", error.message);
      setSubmitStatus({
        text: "An error occurred during ticket submission.",
        submitting: false,
        error: error.message,
      });
      setOtherErrors(fileUploadErrors);
    }
  };

  const handleClearSubmitStatus = () => {
    setSubmitStatus({
      text: null,
      submitting: false,
      error: null,
    });
  };

  return (
    <div>
      {!submitStatus.text ? (
        <div>
          <h3 className="text-almostBlack text-lg md:text-xl font-normal mb-5">
            Support Request
          </h3>
          <p className="mb-4 text-mildGrey text-sm">
            Use this form to submit requests for support.
          </p>
          <div id="First Name and Last Name" className="flex flex-row my-2">
            <FormControl fullWidth={true}>
              <div>
                <label htmlFor="first_name" className="block font-normal">
                  First Name
                </label>
                <OutlinedInput
                  id="first_name"
                  sx={{ width: "95%" }}
                  className="bg-ogWhite"
                  inputRef={firstNameRef}
                ></OutlinedInput>
              </div>
            </FormControl>
            <FormControl fullWidth={true}>
              <div style={{ marginLeft: "5%" }}>
                <label htmlFor="last_name" className="block font-normal">
                  Last Name
                </label>
                <OutlinedInput
                  id="last_name"
                  fullWidth={true}
                  className="bg-ogWhite"
                  inputRef={lastNameRef}
                ></OutlinedInput>
              </div>
            </FormControl>
          </div>
          <div id="Email Address" className="my-2">
            <FormControl fullWidth={true}>
              <label htmlFor="my-email" className="block my-2 font-normal">
                Email address <span className="text-red-500">*</span>
              </label>
              <OutlinedInput
                id="my-email"
                aria-describedby="email-helper-text"
                // sx={{width:"100%"}}
                fullWidth={true}
                className="bg-ogWhite"
                inputRef={emailRef}
                required={true}
                onBlur={(e) => {
                  emailValidator(e.target.value) === "invalid" &&
                    setEmailError(true);
                }}
                onFocus={() => {
                  setEmailError(false);
                }}
              ></OutlinedInput>
              <ErrorText text={"Invalid Email"} error={emailError} />
            </FormControl>
          </div>
          <div id="Subject_Line" className="my-2">
            <FormControl fullWidth={true}>
              <label htmlFor="subject_line" className="block my-2 font-normal">
                Subject Line <span className="text-red-500">*</span>
              </label>
              <OutlinedInput
                id="subject_line"
                aria-describedby="my-helper-text"
                // sx={{width:"100%"}}
                fullWidth={true}
                className="bg-ogWhite"
                inputRef={subjectRef}
                onBlur={(e) => {
                  !e.target.value && setSubjectError(true);
                }}
                onFocus={() => {
                  setSubjectError(false);
                }}
              ></OutlinedInput>
              <ErrorText text={errorText} error={subjectError} />
            </FormControl>
          </div>
          <div id="Describe_Request">
            <FormControl fullWidth={true}>
              <label
                htmlFor="Describe_Request"
                className="block my-2 font-normal"
              >
                Describe Your Request <span className="text-red-500">*</span>
                <br />
                <p className="text-xs my-2 font-normal text-mildGrey">
                  {requestText}
                </p>
              </label>
              <OutlinedInput
                id="Describe_Request"
                aria-describedby="my-helper-text"
                // sx={{width:"100%"}}
                fullWidth={true}
                className="bg-ogWhite"
                multiline={true}
                minRows={5}
                inputRef={requestDetailsRef}
                onBlur={(e) => {
                  !e.target.value && setDetailsError(true);
                }}
                onFocus={() => {
                  setDetailsError(false);
                }}
              ></OutlinedInput>
              <ErrorText text={errorText} error={detailsError} />
            </FormControl>
          </div>
          <div className="my-4">
            <label className="block ">
              <span className="font-normal">Submit Attachments</span>
              <br />
              <span className="text-xs my-2 font-normal">{fileUploadText}</span>
            </label>
            <UploadFileList
              onClick={fileDeleteHandler.bind(
                null,
                setFileNameArray,
                fileNameArray,
                setFilesToUpload,
                filesToUpload
              )}
              fileNameArray={fileNameArray}
            />
            <label
              htmlFor="attachments"
              className="border-2 border-mildGrey p-2 font-normal hover:cursor-pointer"
              onDrop={(e) =>
                handleDropFile(
                  e,
                  setFilesToUpload,
                  filesToUpload,
                  setFileNameArray,
                  fileNameArray
                )
              }
              onDragEnter={(e) => handleDrag(e)}
              onDragOver={(e) => handleDrag(e)}
            >
              Choose Files
            </label>
            <input
              id="attachments"
              type="file"
              multiple={true}
              onChange={(e) =>
                fileHandler(
                  e,
                  setFilesToUpload,
                  filesToUpload,
                  setFileNameArray,
                  fileNameArray
                )
              }
              className="invisible"
            />
          </div>
          <button
            className="bg-veryDarkGrey hover:bg-extraDarkGrey px-4 py-2 rounded-sm text-ogWhite font-normal font-inherit"
            type="submit"
            onClick={formDataValidator}
          >
            Submit
          </button>
        </div>
      ) : (
        <SubmitResponse
          submitStatus={submitStatus}
          otherErrors={otherErrors}
          setSubmitStatus={setSubmitStatus}
          handleClearSubmitStatus={handleClearSubmitStatus}
        />
      )}
    </div>
  );
};

export default TicketsForm;
