import { createSlice } from "@reduxjs/toolkit";

const threadsSlice = createSlice({
  name: "threadsData",
  initialState: {
    recipientActorsData: [],
    senderActorId: "",
    latestThreadId : "",
    fetchAllMessages : true,
    externalSenderName : "",
    attachmentsList :[]
  },
  reducers: {
    setRecipientActorsData(state, action) {
      state.recipientActorsData = action.payload;
    },
    setSenderActorId(state, action) {
      // set a default sender actor id in case there is no 'AGENT' actor id ( id that prefixes A-) is found. 
      // NOTE : Using A- sender id is mandator to post a message according to HubSpot. Checkout below docs link : 
      // HubSpot Docs : https://developers.hubspot.com/docs/api/conversations/conversations#send-messages-to-threads
      state.senderActorId = action.payload !== undefined ? action.payload : process.env.REACT_APP_FALLBACK_ENDERACTORID
    },
    setLatestThreadId(state,action){
      // Setting the thread Id which is latest as per activity date to which a message will be posted ==> 
      state.latestThreadId = action.payload
    },
    setFetchAllMessages(state,action){
      // This state is like a beacon which will be used inside 'ThreadMessages.js' comp to fetch all thread msg. 
      // This state will be turned on inside 'SendMessage.js' when a new message is posted and will be turned off when all threads fetched in 'ThreadMessages.js' useEffect.
      state.fetchAllMessages = action.payload
    },
    setExternalSenderName (state,action){
      state.externalSenderName = action.payload
    },
    updateAttachmentsList (state,action){
      let current = [...state.attachmentsList]
      current[action.payload.messageIndex] = action.payload.data
      state.attachmentsList = current
    },
    resetAttachmentsList (state){
      state.attachmentsList = []
    }

  },
});

export default threadsSlice;
