import { Fragment, useState } from "react";
import TicketsFormWithAI from "../components/tickets-form/TicketFormWithAI";
import CategoriesTab from "../components/tickets-form/TicketFormWithAI/CategoriesTab";
import { useSelector } from "react-redux";
import { categoriesMap } from "../components/tickets-form/ticketFormUtil";
import FilterModal from "../components/FilterModal";

let white_Color =
  "invert(100%) sepia(100%) saturate(0%) hue-rotate(80deg) brightness(204%) contrast(101%)";

const CreateTicketPage = () => {
  const activeCategoryIndex = useSelector(
    (state) => state.tickets.activeCategoryIndex
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [toneFilter, setToneFilter] = useState({ key: "", value: "" });

  const [personaFilter, setPersonaFilter] = useState({ key: "", value: "" });

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div className="w-full md:w-[85%] mx-auto bg-beige1 sm:bg-inherit pt-5">
        <h1 className="text-darkgrey2 text-xl sm:text-2xl  md:text-3xl mt-10 mb-7 sm:mb-12 font-medium px-9 md:px-0">
          Select Your Campaign
        </h1>
        <CategoriesTab />
        <div className="flex items-center justify-between sm:mb-12 px-9 md:px-0">
          <h2 className="font-extrabold text-darkgrey2 text-lg  md:text-xl lg:text-2xl">
            {categoriesMap[activeCategoryIndex]?.label}
          </h2>
          {/* We will enable below filter when available in designs but designed this section to hold filter*/}
          <div
            className={
              toneFilter?.key !== "" && personaFilter?.key !== ""
                ? "flex border px-4 py-1 rounded-lg border-mwOrange2 cursor-pointer bg-mwOrange2 text-white"
                : "flex border px-4 py-1 rounded-lg border-mwOrange2 cursor-pointer"
            }
            onClick={() => handleOpenModal()}
          >
            <div className="mt-1 mr-1">
              <img
                src="icons/filter.svg"
                alt="filter"
                style={{
                  filter:
                    toneFilter?.key !== "" && personaFilter?.key !== ""
                      ? white_Color
                      : "",
                }}
              />
            </div>
            <div>Filter</div>
          </div>
        </div>
        <TicketsFormWithAI
          toneFilter={toneFilter}
          personaFilter={personaFilter}
        />
      </div>
      <FilterModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        setToneFilter={setToneFilter}
        setPersonaFilter={setPersonaFilter}
        toneFilter={toneFilter}
        personaFilter={personaFilter}
      />
    </Fragment>
  );
};

export default CreateTicketPage;
