import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ticketsActions } from "../../../store/redux-index";


const SearchField = () => {
  const searchState = useSelector(state => state.tickets.allFilters.search)
  const dispach = useDispatch()


  const searchFieldHandler = (event) => {
    // console.log("Search Query -> ", event.target.value);
    // setSearchQuery(event.target.value)
    dispach(ticketsActions.setFilters({'search':event.target.value}))
    

  };
  return (
    <FormControl variant="standard" className="w-full md:w-3/4">
      <OutlinedInput
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        value={searchState}
        size="small"
        className="bg-ogWhite"
        onChange={searchFieldHandler}
        sx={{fontSize:'0.875rem',color:'black'}}
        // value={searchQuery}
      ></OutlinedInput>
    </FormControl>
  );
};

export default SearchField;
