// INFO : This component will be placed on top of header and will act as navigation section to HubSpot.

import { Slide } from "@mui/material";

const HubSpotNavigation = ({ hsURL }) => {
  return (
    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
      <nav className="bg-extraDarkGrey2 text-ogWhite">
        <a
          href={hsURL}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row items-center px-4 py-3 justify-end"
        >
          <p className="mr-2">Access Your HubSpot Portal</p>
          <div>

          </div>
          <img src="/icons/hubspot.svg" alt="hubspot" className="w-5"/>
        </a>
      </nav>
    </Slide>
  );
};

export default HubSpotNavigation;
