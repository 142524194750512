// INFO : This is utility component and will be rendered in a component based on the provided props.
//      : If error prop is false, component wont show but will be present in the DOM.

const ErrorText = ({ text, error, styles={} }) => {
  return (
    <div
      className={`text-red-500 font-bold text-xs ${
        error ? "visible" : "invisible"
      } `}
      style={styles}
    >
      {text}
    </div>
  );
};

export default ErrorText
