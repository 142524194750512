import { createSlice } from "@reduxjs/toolkit";

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    loadingReports: false,
    compDetailsLoading : false,
    companyDetails: {
      name : '',
      formattedName : '',
      domain : '',
      has_hubspot_url : '',
      has_brand_url : '',
      company_update : "",
      portal_upload_file_nav:""
    }
  },
  reducers: {

    setCompDetailsLoading(state,action){
      state.compDetailsLoading = action.payload
    },

    setReportsLoading(state, action) {
      state.loadingReports = action.payload;
    },
    setCompanyDetails(state,action){
      state.companyDetails = {...state.companyDetails,...action.payload}
    }
  },
});

export default reportsSlice;
