// INFO : This page will render the reports carousel (and may be other components in future).

import { Link } from "react-router-dom";
import Reports from "../components/reports/reports";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import AppEngagement from "../components/reports/app-engagement";

const ReportsPage = () => {

    const companyName = useSelector(state => state.reports.companyDetails.name)

  return (
    <div className="text-black1">
      <section className="w-11/12 mx-auto mt-28">
        <Reports />
      </section>
      <section className="w-11/12 mx-auto mt-10 mb-10">
        <AppEngagement />
      </section>
      <section className="bg-ogWhite  py-10 text-center">
          <div className="text-shadow text-lg mx-auto flex flex-row items-center h-10 w-fit">
            <div className="bg-mwOrange h-full rounded-l-md w-1"></div>
            <div className="px-2 font-medium">Access Your Open Projects</div>
            <div className="bg-mwOrange w-1 h-full rounded-r-md"></div>
          </div>
        <p className="text-sm my-2 font-roboto">
          View status, track the latest {companyName} project and collaborate with
          your production team.
        </p>
        <Link
          to="/"
          className="flex flex-row text-sm justify-center hover:text-mwOrange"
        >
          <div className="mr-2 my-auto  text-shadow ">
            View Projects
          </div>
          <ArrowForwardIcon fontSize="small" />
        </Link>
      </section>
    </div>
  );
};

export default ReportsPage;
