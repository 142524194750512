// INFO : This utility comp will be rendered in TicketDetailsPage and CreateTicketPage
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ViewAllTickets = ({navLink = "/",label = "View All Tickets"}) => {
  return (
    <div className=" bg-ogWhite py-5">
      <div className="flex items-center w-11/12 mx-auto">
        <ArrowBackIosIcon fontSize="small" />
        <Link to={navLink} className="hover:underline font-normal text-lg">
          {label}
        </Link>
      </div>
    </div>
  );
};

export default ViewAllTickets;
