export const lastActivity = (dateString) => {
    const today = new Date()
    const old = new Date(dateString)

    const daysDiff = (today.getTime() - old.getTime())/(1000 * 3600 * 24).toFixed()
    // console.log( 'Time Diff ', daysDiff) 

    let activity

    if (daysDiff > 365) {
        activity = `${(daysDiff/365).toFixed()} Year${(daysDiff/365).toFixed()>1 ? 's':''} Ago`
    } else if (daysDiff > 30) {
        activity = `${(daysDiff/30).toFixed()} Month${(daysDiff/30).toFixed()>1 ? 's':''} Ago`
    } else if (daysDiff > 7) {
        activity = `${(daysDiff/7).toFixed()} Week${(daysDiff/7).toFixed()>1 ? 's':''} Ago`
    } else if (daysDiff > 1 ) {
        activity = `${daysDiff.toFixed()} Days Ago`
    } else {
        activity = 'Today'
    }

    return activity

}


const dateParser = (dateString)=>{
    const raw = new Date(dateString)

    return raw.toLocaleDateString('default', {month :"long",day : "2-digit", year :"numeric"})
}

export default dateParser