// INFO : This card will render on top of homepage/ticketpage and will display regular updates received from HubSpot API
// This data will be fetched from 'company_update' property of company object.

import { useSelector } from "react-redux";
import LoaderText from "../../utils/LoaderText";

const defaultUpdateText = 'This space is available for company specific updates that will be supplied by the Marketing Wiz team. Constant communication is critical for all of our campaign success which is why we bring this to you front and center.'

const DynamicUpdateCard = () => {
  const companyUpdate = useSelector(
    (state) => state.reports.companyDetails.company_update
  );
  const compDetailsLoading = useSelector(
    (state) => state.reports.compDetailsLoading
  );

  return (
    <div className="bg-mildGrey2 py-4 px-16  md:px-32  text-ogWhite text-center rounded-md">
      <h4 className="font-bold ">Latest Update</h4>

      {compDetailsLoading ? (
        <LoaderText
          text=""
          shouldDisplay={true}
        //   showCircle={false}
          styles={{ fontSize: "0.875rem" }}
        />
      ) : (
        <p className="text-sm mt-2">{!!companyUpdate ? companyUpdate : defaultUpdateText}</p>
      )}
    </div>
  );
};

export default DynamicUpdateCard;
