const EmptyMetricCard = () => {
  return (
    <div className="rounded-sm  bg-ogWhite  w-full flex flex-col items-center relative">
      <img src="./appEngagementEmptyState.webp" width="100%" />
      <div className="absolute top-[20%] vs:top-[25%] md:top-[1/3]  left-[5%] ">
        <div className="text-xs vs:text-base md:text-xl lg:text-2xl xl:text-3xl font-bold w-3/5 mb-2 vs:mb-5 md:mb-10">
          Accelerate Your Content to Clients with MW Connect
        </div>
        <a
          href="https://marketingwiz.co/connect-app/"
          target="_blank"
          rel="noreferrer"
          className="text-xxs vs:text-xs md:text-base  bg-veryDarkGrey h-full  hover:bg-extraDarkGrey p-2 vs:p-4 rounded-sm text-ogWhite font-normal "
        >
          Get Started
        </a>
      </div>
    </div>
  );
};

export default EmptyMetricCard;
