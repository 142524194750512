import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const NavigationMobile = ({ onClick, navList }) => {
  return (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={() => {
        onClick();
      }}
      onKeyDown={() => {
        onClick();
      }}
    >
      <List>
        {navList.filter(x => x!==null).map((item, index) => (
          <div className="mx-auto w-11/12" key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );
};

export default NavigationMobile;
