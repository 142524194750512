// INFO : This function will fetch company name using a company id

const getCompanyDetails = (companyId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(process.env.REACT_APP_GETCOMPANYDETAILS, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          compIdWithProperties:
            companyId +
            "?properties=hubspot_portal_url%2cdigital_assets_url%2cname%2cdomain%2ccompany_update%2cportal_upload_file_nav",
        }),
      });
      const responseBody = await response.json();

      if (responseBody.status !== "OK") throw new Error(responseBody.error);

      // console.log("Company Data : ", responseBody.data);
      const companyNameFormatted = responseBody.data?.name
        .trim()
        .toLowerCase()
        .replaceAll(",", "")
        .replaceAll(" ", "_");
      resolve({
        ...responseBody.data,
        formattedName: companyNameFormatted,
        company_update: responseBody.data.company_update,
        has_hubspot_url: responseBody.data.hubspot_portal_url,
        has_brand_url: responseBody.data.digital_assets_url,
        error: null,
      });
    } catch (err) {
      console.log("Error while fetching company name : ", err.message);
      resolve({ formattedName: "", error: err.message });
    }
  });
};

export default getCompanyDetails;
