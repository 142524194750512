import { configureStore } from "@reduxjs/toolkit";
import ticketsSlice from "./ticketsSlice";
import threadsSlice from "./threadsSlice";
import reportsSlice from "./reportsSlice";

const store = configureStore({
  reducer: {
    tickets: ticketsSlice.reducer,
    threads: threadsSlice.reducer,
    reports: reportsSlice.reducer,
  },
});

export default store;
export const ticketsActions = ticketsSlice.actions;
export const threadsActions = threadsSlice.actions;
export const reportsActions = reportsSlice.actions;
