// INFO : This component will show attached files by the user and will also allow to download and delete them from upload list.
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';

const UploadFileList = (props) => {

    const fileDeleteHandler = (name)=>{
        props.onClick(name)
    }

  return (
    <ul
      className={`my-4 md:w-1/2 overflow-x-auto ${
        props.fileNameArray.length ? "visible" : "invisible"
      } border-2 bg-ogWhite rounded-md font-bold`}
    >
      {props.fileNameArray.map((file,index) => (
        <li
          key={index}
          className="m-2 border-b text-sm flex flex-row justify-between items-center"
        >
          <Tooltip title={file.name} placement="bottom-start"><div className="w-3/4 truncate hover:cursor-default">{file.name}</div></Tooltip>
          <a href={file.url} download className='hover:text-orange-500'>
            <FileDownloadIcon />
          </a>
          <div
            className="hover:cursor-pointer hover:text-orange-500"
            onClick={fileDeleteHandler.bind(null, file.name)}
          >
            <DeleteForeverIcon />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default UploadFileList
