// INFO : This component will render in TicketsDetailPage and will allow users write and send a comment on ticket.
import { Fragment, useRef, useState } from "react";
import fileUplodWithProxyServer from "./api-call-functions/fileUploadWithProxyServer";
import UploadFileList from "../utils/UploadFileList";
import LoaderText from "../utils/LoaderText";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import prepareMessageBody from "./prepareMessagBody";
import { useSelector,useDispatch } from "react-redux";
import { threadsActions } from "../../store/redux-index";
import { useAuth0 } from "@auth0/auth0-react";

const SendMessage = ({ticketSubject}) => {
  const commentRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSendingEmail, setIsSendingEmail] = useState({
    shouldDisplay: false,
    text: null,
    showCircle: false,
  });
  const [isError, setIsError] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileNameArray, setFileNameArray] = useState([]);
  const fileRef = useRef();
  const recipientActorsData = useSelector(
    (state) => state.threads.recipientActorsData
  );
  const senderActorId = useSelector((state) => state.threads.senderActorId);
  const externalSenderName = useSelector(state => state.threads.externalSenderName)
  const dispatch = useDispatch()
  const latestThreadId = useSelector((state) => state.threads.latestThreadId);
  const { user } = useAuth0();
  // ---------------------------------------------------------------------------------------
  // Handlers to attach files either via click or drag-drop
  // ---------------------------------------------------------------------------------------

  const getFileNamesArray = (filesArray) => {
    const temp = Object.keys(filesArray).map((key) => {
      return {
        name: filesArray[key].name,
        url: URL.createObjectURL(filesArray[key]),
      };
    });
    const namesArray = temp.filter(
      (file) => !fileNameArray.map((x) => x.name).includes(file.name)
    );
    setFileNameArray([...fileNameArray, ...namesArray]);
  };

  const fileHandler = (event) => {
    // console.log("Files ---> ", event.target.files);
    getFileNamesArray(event.target.files);
    setFilesToUpload([...filesToUpload, ...event.target.files]);
  };

  // Handler to handle file attachment when user tries to drag-drop =>
  const handleDropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesToUpload([...filesToUpload, ...e.dataTransfer.files]);
      getFileNamesArray(e.dataTransfer.files);
    }
  };

  const fileDeleteHandler = (name) => {
    // console.log(name);
    setFileNameArray(fileNameArray.filter((file) => file.name !== name));
    setFilesToUpload(
      Object.keys(filesToUpload)
        .map((key) => filesToUpload[key].name !== name && filesToUpload[key])
        .filter((i) => typeof i !== "boolean")
    );
  };

  //  Handler which would prevent default behaviour for drag-over and drag-enter events
  //  ...when user tries to attach file with drag-drop =>
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
  };

  // ---------------------------------------------------------------------------------------
  // Main Function Invoked on 'Submit' Button ==>
  // ---------------------------------------------------------------------------------------
  const sendEmailHandler = () => {
    if (latestThreadId === "") {
      setIsSendingEmail({
        shouldDisplay: true,
        showCircle: false,
        text: "No Valid Threads Found For Posting New Message!",
      });
      setIsError(true);
    } else {
      setIsSendingEmail({
        shouldDisplay: true,
        showCircle: false,
        text: `Posting your message to ${latestThreadId}...`,
      });
      setIsError(false);
      let fileUploadCounter = 0;
      const fileIdArray = [];
      const filesCount = Object.keys(filesToUpload).length;

      const callbackFunction = (fileResponseData) => {
        ++fileUploadCounter;
        if (fileResponseData.status === "OK") {
          fileIdArray.push(fileResponseData.fileId);
        }
        if (fileUploadCounter === filesCount) {
          // console.log("All Files Uploaded | Send request to create email->");
          submitMessageWithProxyServer(fileIdArray);
        }
      };

      //If attachments, we will first upload files, get their ids and then submit API request to create emails
      if (filesCount) {
        // console.log("Calling File Upload");
        Object.keys(filesToUpload).forEach((key) =>
          fileUplodWithProxyServer(filesToUpload[key], callbackFunction)
        );
      } else {
        // If no attachments, then directly submit API request to create emails.
        submitMessageWithProxyServer(fileIdArray);
      }
    }
  };

  // ---------------------------------------------------------------------------------------
  // Handler to send Thread Message with proxy server
  // ---------------------------------------------------------------------------------------
  const submitMessageWithProxyServer = async (fileIdArray) => {
    try {
      const response = await fetch(process.env.REACT_APP_SENDMESSAGES, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          threadId: latestThreadId,
          messageBody: prepareMessageBody(
            fileIdArray,
            commentRef.current.value,
            commentRef.current.value,
            ticketSubject,
            recipientActorsData,
            senderActorId,
            externalSenderName,
            user.email
          ),
        }),
      });

      const responseData = await response.json();
      // console.log("Email Id ==> ", responseData);

      if (responseData.status !== "OK")
        throw new Error(
          "Something Wrong Occurred While Posting Message\nError : " +
            responseData.error
        );
      // Resetting All Fields ==>
      setIsSendingEmail({
        shouldDisplay: false,
        text: null,
        showCircle: false,
      });
      // TODO : Add a fetch all threads state
      setIsError(false);
      commentRef.current.value = "";
      fileRef.current.value = "";
      setFileNameArray([]);
      setFilesToUpload([])
      dispatch(threadsActions.setFetchAllMessages(true))
    } catch (error) {
      // console.log(error.message);
      setIsSendingEmail({
        shouldDisplay: true,
        text: error.message,
        showCircle: false,
      });
      setIsError(true);
    }
  };

  return (
    <Fragment>
      <LoaderText
        shouldDisplay={isSendingEmail.shouldDisplay}
        showCircle={isSendingEmail.showCircle}
        text={isSendingEmail.text}
        styles={{ fontSize: "0.8rem", color: `${isError ? "red" : "inherit"}` }}
      />
      <section className="bg-ogWhite border-2 border-borderGrey mb-5">
        {/* Recipients Display Section */}
        <div className="px-2 flex flex-row p-2 items-center">
          <div className="font-medium w-1/6 text-center">Reply To</div>
          <div className="flex flex-row flex-wrap w-5/6 ">
            {recipientActorsData.filter(x => x.id!==process.env.REACT_APP_FALLBACK_ENDERACTORID).map((x, index) => (
              <div
                className="bg-default border border-borderGrey font-normal w-fit px-1 m-1"
                key={index}
              >
                {x.email}
              </div>
            ))}
          </div>
        </div>
        {/* Text Input Section */}
        <textarea
          placeholder="Write a response..."
          className="w-full outline-none border-t-2 px-2 font-normal"
          ref={commentRef}
          // onChange={textChangeHandler}
          onChange={(e) => {
            e.target.value.length > 1
              ? setIsDisabled(false)
              : setIsDisabled(true);
          }}
        />
        <div className="bg-default p-5">
          <UploadFileList
            onClick={fileDeleteHandler}
            fileNameArray={fileNameArray}
          />
          <div className=" flex flex-row items-center justify-between">
            <label
              htmlFor="attachFile"
              className="hover:cursor-pointer w-1/3 flex flex-row py-4 hover:text-orange-500"
              onDrop={handleDropFile}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
            >
              <div className="rotate-45">
                <AttachFileIcon />
              </div>
              <div className="font-medium mx-4 ">Attach A File</div>
            </label>
            <label className="font-normal text-mildGrey mx-2">
              (Max File Upload Size 30MB)
            </label>
            <input
              type="file"
              id="attachFile"
              // value=""
              onChange={fileHandler}
              ref={fileRef}
              multiple={true}
              className="invisible  w-5"
            />
            <button
              className={`${
                isDisabled ? "bg-slate-400" : "bg-veryDarkGrey"
              }    hover:bg-extraDarkGrey px-4 py-2 rounded-sm text-ogWhite font-medium font-inherit ${
                isDisabled ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              disabled={isDisabled}
              onClick={sendEmailHandler}
            >
              Send
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SendMessage;
