import Box from "@mui/material/Box";
import SdCardAlertOutlinedIcon from "@mui/icons-material/SdCardAlertOutlined";
import { useSelector } from "react-redux";
import LoaderText from "../utils/LoaderText";

const EmptyCard = () => {

  const loadingReports = useSelector(state => state.reports.loadingReports)

  return (
    <Box className=" bg-ogWhite h-40  rounded-lg text-default flex flex-row shrink-0 shadow-custom">
      <div className="w-2 bg-orange-500 rounded-l-lg"></div>
      {loadingReports && <LoaderText shouldDisplay={true} text='Loading Reports...'/>}
      {!loadingReports && <div className="p-2 w-full flex flex-col items-center">
        <div className="text-center my-auto">
          <SdCardAlertOutlinedIcon
            className="text-orange-500 bg-orange-200 rounded-full p-1 mx-auto"
            sx={{ fontSize: 36 }}
          />
        </div>
        <p className="w-full my-auto px-2 md:w-3/4 text-center font-roboto">
          No Reports Available Yet. The MarketingWiz Team is compiling this for
          your first one and you will see it appear in this list when ready
        </p>
      </div>}
    </Box>
  );
};

export default EmptyCard;
