import ViewFilter from "../components/homepage/tickets/ViewFilter";
import StatusFilter from "../components/homepage/tickets/StatusFilter";
import SearchField from "../components/homepage/tickets/SearchField";
import TicketsTable from "../components/homepage/tickets/TicketsTable";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import dateParser, { lastActivity } from "../components/utils/dateParser";
import { stagingStatusMap, prodStatusMap } from "./statusMap";
import { useDispatch, useSelector } from "react-redux";
import { ticketsActions } from "../store/redux-index";
import DynamicContent from "../components/homepage/dynamic-content";
import DynamicUpdateCard from "../components/homepage/dynamic-content/dynamic-update-card";
import Blogs from "../components/homepage/blogs/blogs";

const Homepage = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const batchURL = useSelector((state) => state.tickets.newData.batchURL);

  useEffect(() => {
    const ticketStatusMap =
      process.env.REACT_APP_STATUSMAP === "staging"
        ? stagingStatusMap
        : prodStatusMap;
    // We are making HTTP request to receive all tickets data ==>
    const fetchAllTicketsWithProxyServer = async () => {
      // console.log("Running Tickets Fetching", batchURL);
      try {
        dispatch(
          ticketsActions.setLoadingState({
            text: "Please wait, loading tickets...",
            show: true,
            showCircle: true,
          })
        );

        const response = await fetch(
          process.env.REACT_APP_GETALLTICKETS_PRXYURL,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            // body:JSON.stringify({url:process.env.REACT_APP_GETALLTICKETS_HSURL})
            body: JSON.stringify({ url: batchURL }),
          }
        );
        const responseData = await response.json();
        // dispatch(ticketsActions.setLoadingState({text:'',show:false,showCircle:false}))

        const jsonData = JSON.parse(responseData);
        // Updating the ticketsObject ==>
        const parsedData = jsonData.results.map((item) => {
          const primaryCompId = item.associations?.companies?.results?.find(
            (x) => x.type === "ticket_to_company"
          )?.id;
          const threadId = item.associations?.conversations?.results
            .filter((x) => x.type === "ticket_to_conversation")
            .map((x) => x.id)
            .join("+");
          const temp = {
            id: item.id.toString(),
            subject: item.properties.subject?.toString(),
            created: dateParser(item.properties.createdate),
            lastActive: lastActivity(item.properties.hs_lastmodifieddate),
            lastActiveNumeric: item.properties.hs_lastmodifieddate,
            status: ticketStatusMap[item.properties.hs_pipeline_stage],
            companyId: primaryCompId ? primaryCompId : "",
            contactIdList: item.associations?.contacts?.results?.map(
              (item) => item.id
            ),
            threadId: threadId,
          };
          return temp;
        });
        // console.log("Tickets data ==> ",parsedData)
        const newURL =
          jsonData.paging !== undefined ? jsonData.paging.next.link : "last";
        dispatch(
          ticketsActions.collectData({ batchURL: newURL, data: parsedData })
        );
        dispatch(ticketsActions.runFilters());
      } catch (error) {
        const errorText = `No Tickets Associated. Please contact support@marketingwiz.co if this seems to be in error.\nError Found : ${error.message}`;
        dispatch(
          ticketsActions.setLoadingState({
            text: errorText,
            show: true,
            showCircle: false,
          })
        );
        console.log(error.message);
      }
    };

    // NOTE : This useEffect is designed to fire API calls using below function till all tickets are fetched from database.
    // To run consequtively, we are updating batchURL with every API run hence useEffect dependency array also changes
    // Hence useEffect keeps on running till batchURL found as 'last'
    batchURL !== "last" && fetchAllTicketsWithProxyServer();
    batchURL === "last" &&
      dispatch(
        ticketsActions.setLoadingState({
          text: "",
          show: false,
          showCircle: false,
        })
      );
  }, [user.email, dispatch, batchURL]);

  return (
    <div className="w-11/12 mx-auto my-10">
      <DynamicUpdateCard />

      <section className="mt-8">
        <h1 className="text-2xl md:text-3xl mb-8">Tickets</h1>
        <div className="flex flex-col md:flex-row items-center text-black">
          <div className="w-full md:w-2/5">
            <SearchField />
          </div>
          <div className="w-full md:w-30perc  flex flex-col md:flex-row items-center mt-5 md:mt-0">
            <label className=" w-full md:w-1/2 text-left md:text-right">
              View
            </label>
            <ViewFilter />
          </div>
          <div className="w-full md:w-30perc  flex flex-col md:flex-row items-center">
            <label className=" w-full md:w-1/2 text-left md:text-right">
              Status
            </label>
            <StatusFilter />
          </div>
        </div>
        <div className="mt-5">
          <TicketsTable />
        </div>
      </section>
      <section className="mt-4">
        <DynamicContent />
      </section>
      <section className="mt-8">
        <Blogs />
      </section>
    </div>
  );
};

export default Homepage;
