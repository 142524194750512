// INFO : This card will accept required parameters to render a card with rounder-corners, and shadow for each engagement metric.

const MetricCard = ({ iconUrl, title, timePeriod, metricValue }) => {
  return (
    <div className="w-[240px] shadow-custom h-[184px] mr-[20px] shrink-0 rounded-sm p-4 bg-ogWhite">
      <div className="h-10 flex align-baseline">
        <img src={iconUrl} alt={title} className="max-h-10" />
      </div>
      <div className="mt-4 mb-2 font-bold  text-sm">{title}</div>
      <div className="text-xs text-mildGrey2 mb-4">{timePeriod}</div>
      <div className="font-bold  text-base">{metricValue}</div>
    </div>
  );
};

export default MetricCard;
