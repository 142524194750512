/**INFO : This component is responsible for :
 * Step # 1 : Check access token tag available in cookies
 * Step # 2 : If cookie available, then check if it is expired or live.
 * Step # 3 : If expired or if cookie not available, both cases, take env var appkeysecret, refresh token and get new token
 * Step # 4 : When new token is generated , save this to cookie.
 * Step # 5 : If new token or live token, both cases, we now have the token, so we return back access token.
 */

import fetchAccessToken from "./fetchAccessToken";

const getAccessToken = (refresh) => {
  return new Promise((resolve, reject) => {
    // Step # 1 : Check if token tag available in cookie :
    let accessToken = { token: null, error: null };

    const cookieData = document.cookie
      .split(";")
      .find((x) => x.trim().startsWith("mwDropboxAccessToken"));

    if (!!cookieData && refresh === false) {
      // Step # 2 : Check if cookie expired  :
      accessToken.token = cookieData.trim()?.split("=")[1];
      // console.log("Access token found in cookie : ", accessToken.token);
      resolve(accessToken);
    } else {
      // Step # 3 : Get Refresh Token :

      const callbackFunc = (result) => {
        if (result.error === null) {
          accessToken.token = result.access_token;
          const timeNow = new Date();
          const expiryHours = result.expires_in * 1000; // Dropbox returns expiry time in seconds ( generally 14400 = 4 hours)
          const expiryTime = timeNow.setTime(timeNow.getTime() + expiryHours);
          document.cookie = `mwDropboxAccessToken=${accessToken.token}; expires=${expiryTime}`;
          // console.log(
          //   "Data saved into cookie and access token is ",
          //   accessToken.token
          // );
          resolve(accessToken);
        } else {
          accessToken.error = result.error;
          console.log("access token not generated, Error Is :  ", result.error);
          reject(accessToken);
        }
      };

      fetchAccessToken(callbackFunc);
    }
  });
};

export default getAccessToken;
