import { Fragment } from "react";
import TicketsForm from "../components/tickets-form/TicketForm";
import ViewAllTickets from "../components/utils/ViewAllTickets";

const OtherTicketPage = () => {
  return (
    <Fragment>
      <ViewAllTickets navLink="/" label="Back"/>
      <div className="w-11/12 mx-auto mt-28">
        <h1 className="text-2xl  md:text-3xl mb-8">File a support ticket</h1>
        <div className="mt-16 mb-20">
          <TicketsForm />
        </div>
      </div>
    </Fragment>
  );
};

export default OtherTicketPage;
