import parseEmailText from "../utils/parseEmailText";

const prepareMessageBody = (
  fileIdArray,
  simpleText,
  richHTMLText,
  ticketSubject,
  actorsData,
  senderActorId,
  externalSenderName,
  senderEmail
) => {
  const recipientsData = actorsData.map((actor) => {
    const temp = {
      // actorId: `E-${actor.email}`, // Disabled this field to fix error MSP-139 as suggested by HubSpot Team
      recipientField: "TO",
      deliveryIdentifiers: [
        {
          type: "HS_EMAIL_ADDRESS",
          value: actor.email,
        },
      ],
    };
    return temp;
  });

  let externalSenderStringTag = "";
  /**NOTE: This tag is required when external client is logged in and wants to post a message.
   * As external client wont be assigned an Agent type ( A-) actor id hence such users cant post a message by themselves.
   * Hence we will use the fallback sender actor id to send email message but will add below string to alert all recipients that this is sent by ...
   * ... a certain email with the help of MW Support because the actual sender email would be shown as support@marketingwiz.com which is attached with ...
   * ... fallback sender actor id.
   */

  if (senderActorId === process.env.REACT_APP_FALLBACK_ENDERACTORID) {
    const nameString =
      externalSenderName !== "N/A" ? ` from ${externalSenderName}` : "";
    externalSenderStringTag = `<br><div>[This email is sent${nameString} at ${senderEmail} via MW Client Portal]</div><br>`;
  }

  // "<div style=\"text-align: left\">Hello </div>",
  //  "<div><div style=\"{{margin:&quot;2px&quot;}}\"
  // <div style=\"margin:2px\">
  const body = {
    type: "MESSAGE",
    text: simpleText,
    richText: `<div><div>${parseEmailText(
      richHTMLText
    )}</div>${externalSenderStringTag}</div>`,
    attachments: fileIdArray.map((x) => {
      return { fileId: x };
    }),
    recipients: recipientsData,
    senderActorId: senderActorId,
    channelId: "1002",
    channelAccountId: process.env.REACT_APP_THREADCHANNELAC_ID,
    subject : "RE : "+ticketSubject
  };

  // console.log("Message Body Prepared",body);
  return body;
};

export default prepareMessageBody;
