// INFO : This component will render when a ticket submit button is pressed :
import LoaderText from "../../utils/LoaderText";
import ErrorText from "../../utils/ErrorText";
import { useNavigate } from "react-router-dom";

const SubmitResponse = ({
  submitStatus,
  otherErrors,
  setSubmitStatus,
  handleClearSubmitStatus,
}) => {
  const navigate = useNavigate();

  const viewTickets = () => {
    navigate("/");
  };
  return (
    <div className="mx-auto">
      <LoaderText
        shouldDisplay={true}
        text={submitStatus.text}
        showCircle={submitStatus.submitting}
        styles={{
          justifyContent: "start",
          padding: "0px",
          margin: "0px",
          textAlign: "left",
        }}
      />
      {submitStatus.error && (
        <p className="text-red-500 font-bold">Error : {submitStatus.error}</p>
      )}
      <br />
      {!submitStatus.error && otherErrors.length !== 0 && (
        <div className="italic">
          Ticket creation is successful but found below errors while uploading
          attachments | Contact{" "}
          <span className="font-bold">support@marketingwiz.co</span> if error
          persists :
          {otherErrors.map((x, index) => (
            <div key={index}>
              <ErrorText error={true} text={x} />
            </div>
          ))}
        </div>
      )}
      {!submitStatus.submitting && (
        <div className="flex space-x-2">
          <div>
            <button
              onClick={viewTickets}
              className="bg-veryDarkGrey hover:bg-extraDarkGrey px-4 py-2 rounded-sm text-ogWhite font-bold font-inherit"
            >
              View My Tickets
            </button>
          </div>
          <div>
            <button
              onClick={handleClearSubmitStatus}
              className="bg-veryDarkGrey hover:bg-extraDarkGrey px-4 py-2 rounded-sm text-ogWhite font-bold font-inherit"
            >
              New Ticket
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitResponse;
